import React, { Component } from "react";
import { BackendInterface } from "../../Helper/BackendInterface";
import "./News.scss";
import Icon from "../../Resources/Icons/Icon";
import Utils from "../../Helper/Utils";
import SwipeableViews from "react-swipeable-views";
import { Spinner } from "@onedash/tools";
export interface NewsProps {}

export interface NewsState {}

class News extends Component<NewsProps, NewsState> {
	spinner = React.createRef<Spinner>();
	swipeableActions: any;

	state = {
		news: {
			bekanntmachungen: [],
			presseMitteilungen: [],
		} as NewsList,
		loading: false,
		firstLoaded: false,
		currentPageIndex: 0,
	};
	skip = 0;
	componentDidMount() {
		this.getNews();
		Utils.setBackPath("/");
	}

	getNews = () => {
		if (!this.state.loading) {
			this.setState({ loading: true }, () => {
				BackendInterface.getDataByUrl(`/news/get?skip=${this.skip}`, (data: NewsList) => {
					setTimeout(() => {
						if (this.spinner.current) this.spinner.current.hide();
						let news = this.state.news;

						news.bekanntmachungen = news.bekanntmachungen.concat(data.bekanntmachungen);
						news.presseMitteilungen = news.presseMitteilungen.concat(data.presseMitteilungen);

						setTimeout(() => {
							this.setState({ news, loading: false, firstLoaded: true });
							this.skip += 10;
							this.UpdateSwipeableViewHeight();
						}, 200);
					}, 500);
				});
			});
		}
	};
	changePageIndex = (index: number) => {
		this.setState({ currentPageIndex: index });
	};
	UpdateSwipeableViewHeight = () => {
		if (this.swipeableActions) this.swipeableActions.updateHeight();
	};

	render() {
		let { news } = this.state;
		return (
			<div className="news">
				<a
					className="additional-link"
					href="https://www.vg-wittlich-land.de/vg_wittlich_land/Aktuelles/Informationen%20zum%20Corona-Virus/">
					Hier gelangen Sie zu <br />
					weiteren Informationen zu Covid-19
				</a>
				<div className="tab-wrapper">
					<button
						className={this.state.currentPageIndex === 0 ? "tab-name active" : "tab-name"}
						onClick={() => {
							this.setState({ currentPageIndex: 0 });
						}}>
						Pressemitteilungen
					</button>
					<button
						className={this.state.currentPageIndex === 1 ? "tab-name active" : "tab-name"}
						onClick={() => {
							this.setState({ currentPageIndex: 1 });
						}}>
						Amtliche Mitteilungen
					</button>
				</div>
				<SwipeableViews
					animateHeight={true}
					action={((actions: any) => (this.swipeableActions = actions)) as any}
					onChangeIndex={this.changePageIndex}
					index={this.state.currentPageIndex}>
					<div className="pressemitteilungen">
						{news.presseMitteilungen &&
							news.presseMitteilungen.map((singleNews, index) => (
								<SingleNews
									key={index}
									url={singleNews.url}
									image={singleNews.image}
									title={singleNews.title}
									text={singleNews.text}
									month={singleNews.month}
								/>
							))}
					</div>
					<div className="bekanntmachungen">
						{news.bekanntmachungen &&
							news.bekanntmachungen.map((singleNews, index) => (
								<SingleNews
									key={index}
									url={singleNews.url}
									title={singleNews.title}
									text={singleNews.text}
									month={singleNews.month}
								/>
							))}
						{this.state.firstLoaded && (
							<button onClick={this.getNews} className="load-more">
								<Icon name="back" deg={270} size={50} />
							</button>
						)}
					</div>
				</SwipeableViews>
				<Spinner defaultVisible ref={this.spinner} />
			</div>
		);
	}
}
export interface iSingleNews {
	url: string;
	title: string;
	text: string;
	month?: string;
	image?: string;
}
export interface NewsList {
	bekanntmachungen: iSingleNews[];
	presseMitteilungen: iSingleNews[];
}

const SingleNews: React.SFC<iSingleNews> = ({ url, title, text, month, image }) => {
	return (
		<a href={url} target="_blank" rel="noopener noreferrer" className={!image ? "single-news" : "image-news"}>
			{image && (
				<div className="image">
					<img alt={title} src={image} />
				</div>
			)}
			<div className="news-text">
				<div className="title">{title}</div>
				<div className="text">{text}</div>
				<div className="month">{month}</div>
			</div>
		</a>
	);
};

export default News;
