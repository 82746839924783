import React from "react";
import Department from "./Department";
import Utils from "../../Helper/Utils";
import "./DepartmentSelection.scss";
import { Spinner } from "@onedash/tools";
import { useDepartmentsQuery } from "../../Resources/Generated/gql-types";
import ENV from "../../Helper/ENV";

export interface DepartmentSelectionProps {}

const DepartmentSelection = (props: DepartmentSelectionProps) => {
	const spinner = React.useRef<Spinner>(null);
	const { data: departmentData } = useDepartmentsQuery({
		context: {
			headers: {
				"app-id": ENV.default.appId,
			},
		},
		onCompleted: () => {
			if (spinner.current) spinner.current.hide();
		},
	});

	React.useEffect(() => {
		Utils.setBackPath("/");
	}, []);
	const departments = departmentData?.departments ?? [];

	return (
		<div className="department-selection">
			<h2 className="department-selection-title">Wählen Sie eine Abteilung</h2>
			<div className="department-list">
				{departments.map((department) => (
					<Department
						services={department.services as any}
						description={department.description ?? ""}
						title={department.name}
						key={department.id}
					/>
				))}
			</div>

			<Spinner defaultVisible ref={spinner} />
		</div>
	);
};

export default DepartmentSelection;
