import React, { Component } from "react";
import "./About.scss";
import Icon from "../../Resources/Icons/Icon";
import { t } from "../../Helper/TranslationHandler";
import { isIOS } from "react-device-detect";
import Impress from "./Impress";
import Privacy from "./Privacy";
import iosAction from "../../Resources/Images/share.png";
import { Dialog } from "@onedash/tools";
import MediaRender from "../../Pages/App/MediaRender";
export interface AboutProps {}

class About extends Component<AboutProps> {
	impressRef = React.createRef<Dialog>();
	privacyRef = React.createRef<Dialog>();
	openingTimes = React.createRef<Dialog>();
	iosInstallDialog = React.createRef<Dialog>();

	state = {
		installable: false,
	};

	isInStandaloneMode = () => "standalone" in window.navigator && (window.navigator as any).standalone;

	checkInstallability = () => {
		let store = window.reduxStore;
		let installable = store.getState().appInstallAvailable;
		if (isIOS) {
			installable = installable && !this.isInStandaloneMode();
		}
		if (installable !== this.state.installable) {
			this.setState({ installable });
		}
	};
	componentDidMount() {
		this.checkInstallability();
		window.reduxStore.subscribe(this.checkInstallability);
	}
	installApp = () => {
		if (isIOS) {
			if (this.iosInstallDialog.current) this.iosInstallDialog.current.show();
		} else {
			if (window.deferredPrompt) window.deferredPrompt.prompt();
		}
	};

	render() {
		return (
			<div className="about">
				<div className="about-items">
					{(this.state.installable || isIOS) && (
						<button onClick={this.installApp} className="item">
							<Icon name="app" size={38} color="#064678" />
							<div>
								<h2 className="title">{t("about.install.title")}</h2>
								<p className="text">{t("about.install.text")}</p>
							</div>
						</button>
					)}
					<MediaRender type="mobile">
						<button
							className="item"
							onClick={() => {
								window.reduxStore.dispatch({ type: "SHOW_TEXT_SIZE" });
							}}>
							<Icon name="text-size" size={38} color="#064678" />
							<div>
								<h2 className="title">{t("about.textSize.title")}</h2>
								<p className="text">{t("about.textSize.text")}</p>
							</div>
						</button>
					</MediaRender>

					<a href="https://www.vg-wittlich-land.de" className="item">
						<Icon name="info" size={38} color="#064678" />
						<div>
							<h2 className="title">{t("about.website.title")}</h2>
							<p className="text">{t("about.website.text")}</p>
						</div>
					</a>

					<button
						className="item"
						onClick={() => {
							if (this.openingTimes.current) this.openingTimes.current.show();
						}}>
						<Icon name="clock" size={38} color="#064678" />
						<div>
							<h2 className="title">{t("about.openingTimes.title")}</h2>
							<p className="text">{t("about.openingTimes.text")}</p>
						</div>
					</button>
					<a href="https://onedash.atlassian.net/servicedesk/customer/portals" className="item">
						<Icon name="speaker" size={38} color="#064678" />
						<div>
							<h2 className="title">{t("about.issues.title")}</h2>
							<p className="text">{t("about.issues.text")}</p>
						</div>
					</a>
					<a
						href="/privacy"
						className="item"
						onClick={(e) => {
							e.preventDefault();
							if (this.privacyRef.current) this.privacyRef.current.show();
						}}>
						<Icon name="privacy" size={38} color="#064678" />
						<div>
							<h2 className="title">{t("about.privacy.title")}</h2>
							<p className="text">{t("about.privacy.text")}</p>
						</div>
					</a>
					<a
						href="/impress"
						onClick={(e) => {
							e.preventDefault();
							if (this.impressRef.current) this.impressRef.current.show();
						}}
						className="item">
						<Icon name="user" size={38} color="#064678" />
						<div>
							<h2 className="title">{t("about.impress.title")}</h2>
							<p className="text">{t("about.impress.text")}</p>
						</div>
					</a>
				</div>

				<Dialog
					buttons={[]}
					closeable
					title="App auf Ihrem IOS - Gerät installieren"
					ref={this.iosInstallDialog}>
					<div className="ios-install-dialog">
						<ul>
							<li>
								1. Klicken Sie zuerst auf das <img src={iosAction} width={13} alt="IOS - Share" /> Icon.
							</li>
							<li>
								2. Wählen Sie nun den Punkt <br />
								<Icon name="app" size={13} color="#007aff" />{" "}
								<span className="bold">Zum Home - Bildschirm</span> aus.
							</li>
							<li>3. Anschließend können sie die Anwendung auf Ihrem Gerät installieren.</li>
						</ul>
					</div>
				</Dialog>

				<Dialog closeable title="Öffnungszeiten" buttons={[]} ref={this.openingTimes}>
					<div className="about-text">
						<h1>Bürgerbüro Wittlich</h1>
						<div className="opening-entries">
							<div className="entry">
								<span>Montag</span>
								<span> 07:30 Uhr - 17:00 Uhr</span>
							</div>
							<div className="entry">
								<span>Dienstag</span>
								<span> 07:30 Uhr - 17:00 Uhr</span>
							</div>
							<div className="entry">
								<span>Mittwoch</span>
								<span> 07:30 Uhr - 13:00 Uhr</span>
							</div>
							<div className="entry">
								<span>Donnerstag</span>
								<span> 07:30 Uhr - 17:00 Uhr</span>
							</div>
							<div className="entry">
								<span>Freitag</span>
								<span> 07:30 Uhr - 13:00 Uhr</span>
							</div>
						</div>

						<h1>Übrige Büros Wittlich</h1>
						<div className="opening-entries">
							<div className="entry">
								<span>Montag</span>
								<span>
									08:30 Uhr - 12:30 Uhr
									<br />
									14:00 Uhr - 16:00 Uhr
								</span>
							</div>
							<div className="entry">
								<span>Dienstag</span>
								<span>
									08:30 Uhr - 12:30 Uhr
									<br />
									14:00 Uhr - 16:00 Uhr
								</span>
							</div>
							<div className="entry">
								<span>Mittwoch</span>
								<span>08:30 Uhr - 13:00 Uhr</span>
							</div>
							<div className="entry">
								<span>Donnerstag</span>
								<span>
									08:30 Uhr - 12:30 Uhr
									<br />
									14:00 Uhr - 16:00 Uhr
								</span>
							</div>
							<div className="entry">
								<span>Freitag</span>
								<span>08:30 Uhr - 13:00 Uhr</span>
							</div>
						</div>

						<h1>Bürgerbüro Manderscheid</h1>
						<div className="opening-entries">
							<div className="entry">
								<span>Montag</span>
								<span> geschlossen</span>
							</div>
							<div className="entry">
								<span>Dienstag</span>
								<span>geschlossen</span>
							</div>
							<div className="entry">
								<span>Mittwoch</span>
								<span>
									09:00 Uhr - 12:30 Uhr
									<br />
									14:00 Uhr - 17:00 Uhr
								</span>
							</div>
							<div className="entry">
								<span>Donnerstag</span>
								<span>geschlossen</span>
							</div>
							<div className="entry">
								<span>Freitag</span>
								<span>geschlossen</span>
							</div>
						</div>
					</div>
				</Dialog>

				<Dialog buttons={[]} closeable title="Impressum" ref={this.impressRef}>
					<Impress inline />
				</Dialog>
				<Dialog buttons={[]} closeable title="Datenschutz-erklärung" ref={this.privacyRef}>
					<Privacy inline />
				</Dialog>
			</div>
		);
	}
}

export default About;
