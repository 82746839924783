import Cookies from "js-cookie";

export default class Utils {
	public static setBackPath = (path: string) => {
		window.reduxStore.dispatch({ type: "SET_BACK_BUTTON_PATH", path });
	};

	public static updateAvailable = () => {
		window.reduxStore.dispatch({ type: "UPDATE_AVAILABLE" });
	};
	public static isInstallable = () => {
		window.reduxStore.dispatch({ type: "APP_INSTALL_AVAILABLE" });
	};
	static generateGuid = () => {
		var S4 = function () {
			return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
		};
		return S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4();
	};

	public static formatDate(date: Date) {
		var d = new Date(date),
			month = "" + (d.getMonth() + 1),
			day = "" + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) month = "0" + month;
		if (day.length < 2) day = "0" + day;

		return `${day}.${month}.${year}`;
	}

	public static smoothScrollTop = () => {
		const c = document.documentElement.scrollTop || document.body.scrollTop;
		if (c > 0) {
			window.requestAnimationFrame(Utils.smoothScrollTop);
			document.body.scrollTo(0, c - c / 8);
		}
	};

	public static cookieAllowed = (): boolean => {
		return Cookies.get("settings-cookie") === "true";
	};
	public static appIsOnline() {
		window.reduxStore.dispatch({ type: "NETWORK_STATE", online: true });
	}
	public static appIsOffline() {
		window.reduxStore.dispatch({ type: "NETWORK_STATE", online: false });
	}
}
