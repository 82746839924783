const ENV = {
	default: {
		apiURL: String(window._env.REACT_APP_BACKEND_ENDPOINT),
		newAPI: String(window._env.REACT_APP_NEW_API),
		gqlURL: String(window._env.REACT_APP_GQL_URL),
		appId: String(window._env.REACT_APP_APP_ID),
	},
	serverStatus: {
		url: String(window._env.REACT_APP_STATUS_URL),
		id: String(window._env.REACT_APP_STATUS_ID),
	},
};

export default ENV;
