const TRANSLATIONS = {
	about: {
		impress: { text: "Wer ist Verantwortlich für die APP und deren Inhalte.", title: "Impressum" },
		install: {
			text: "Sie wollen die App auf Ihr Gerät herunterladen? Kein Problem, Sie sind nur ein Klick davon entfernt.",
			title: "Auf Ihr Gerät speichern",
		},
		issues: {
			text: "Sie haben einen Fehler gefunden, den Sie melden möchten? Dann finden Sie hier den Issue Tracker.",
			title: "Fehler",
		},
		openingTimes: {
			text: "Sie möchten uns besuchen kommen? Dann finden Sie hier die Öffnungszeiten der Büros.",
			title: "Öffnungszeiten",
		},
		privacy: {
			text: "Ihr Datenschutz ist uns wichtig und deswegen erfahren Sie hier, welche Daten von uns erhoben werden.",
			title: "Datenschutz",
		},
		textSize: {
			text: "Sie können einige Texte nicht lesen? Dann passen Sie hier die Schriftgröße an.",
			title: "Schriftgröße",
		},
		website: {
			text: "Sie suchen nach weiteren Informationen zur Verbandsgemeinde Wittlich - Land? \nDann besuchen Sie die Webseite der Verbandsgemeinde Wittlich - Land",
			title: "Webseite",
		},
	},
	app: {
		backToHome: "Zurück zur Startseite",
		privacyAndImpressRead:
			"Hiermit bestätigen Sie, dass Sie unsere Datenschutzerklärung und unser Impressum gelesen und verstanden haben.",
		title: "Mein Wittlich - Land",
	},
	base: {
		app: { offline: "Das Gerät ist offline", online: "Das Gerät ist online" },
		cookie: {
			accept: "Akzeptieren",
			decline: "Ablehnen",
			text: "Diese App verwendet mit ihrer Einwilligung Cookies, um Ihre persönlichen Einstellungen zu speichern.",
		},
		update: {
			button: "App Updaten",
			text: 'Für diese Anwendung ist ein Update verfügbar.\nBitte installieren Sie das Update, indem Sie "App Updaten" auswählen.',
			title: "Ein Update ist verfügbar",
		},
	},
	dataEntry: {
		additionalMessage: {
			label: "Zusätzliche Nachricht",
			placeholder: "Geben Sie hier eine Nachricht ein, wenn Sie möchten",
		},
		address: { label: "Adresse", placeholder: "Geben Sie Ihre Adresse ein" },
		email: { label: "Email - Adresse", placeholder: "Geben Sie Ihre Email - Adresse ein" },
		firstName: { label: "Vorname", placeholder: "Geben Sie Ihren Vornamen ein" },
		lastName: { label: "Nachname", placeholder: "Geben Sie Ihren Nachnamen ein" },
		location: { label: "Wohnort", placeholder: "Geben Sie Ihren Wohnort ein" },
		city: { label: "Stadt", placeholder: "Geben Sie Ihre Stadt ein" },
		country: { label: "Land", placeholder: "Geben Sie Ihr Land ein" },
		street: { label: "Straße", placeholder: "Geben Sie Ihre Straße ein" },
		houseNumber: { label: "Hausnummer", placeholder: "Geben Sie Ihre Hausnummer ein" },
		main: {
			address: "Adresse",
			email: "Email - Adresse",
			name: "Name",
			personalDataTitle: "Ihre persönlichen Daten",
			phone: "Telefonnummer",
		},
		name: {
			label: "Vor- und Nachname",
			placeholder: "Geben Sie hier Ihren Namen ein",
			placeholder2: "Geben Sie hier den Namen ein",
		},
		phone: { label: "Telefonnummer", placeholder: "Geben Sie Ihre Telefonnummer ein" },
		plz: { label: "Postleitzahl", placeholder: "Geben Sie Ihre Postleitzahl ein" },
		title: "Geben Sie ihre persönlichen Daten an",
	},
	fundbuero: {
		confirmation: {
			failure: "Es ist ein Fehler beim Melden des Gegenstands aufgetreten",
			itemData: {
				foundAddress: "Fundort",
				location: "Ortsgemeinde",
				objectAdditionalData: "Fundbeschreibung",
				objectType: "Art des Gegenstandes",
				title: "Daten des Fundgegenstands",
			},
			personalData: {
				email: "Email Adresse",
				name: "Name",
				phone: "Telefonnummer",
				title: "Ihre persönlichen Angaben",
			},
			sendButton: "Fundgegenstand melden",
			success: "Vielen Dank für Ihre Mithilfe",
			title: "Überprüfen Sie bitte Ihre Angaben",
		},
		found: {
			address: { label: "Adresse", placeholder: "Geben Sie die ungefähre Adresse an" },
			location: { label: "Fundort", placeholder: "Wählen Sie einen Ort" },
			objectAdditionalData: {
				label: "Fundumstände",
				placeholder: "Beschreiben Sie kurz, wo und wann sie den Gegenstand gefunden haben",
			},
			objectType: { label: "Kategorie", placeholder: "Wählen Sie eine Kategorie" },
			title: "Geben Sie bitte einige allgemeine Informationen an",
		},
		list: { title: "Alle Fundsachen, aufgelistet nach Ortsgemeinden" },
		selection: {
			found: { text: "Haben Sie eine Fundsache gefunden und möchten diese melden?", title: "Fundsache melden" },
			lost: {
				text: "Haben Sie einen Gegenstand verloren? Dann suchen Sie hier direkt danach.",
				title: "Gegenstand verloren",
			},
			title: "Wählen Sie Ihr Anliegen",
		},
		upload: {
			btn: "Bilder hochladen",
			selectImage: "Wählen Sie ein Bild",
			sizeError: "Das Bild ist zu groß",
			title: "Bitte wählen Sie mindestens ein Bild des Gegenstandes aus",
			uploadLabel: "Maximale Bildgröße: 5MB",
		},
	},
	home: {
		fundbuero: {
			text: "Wenn Sie eine Fundsache melden möchten, oder etwas Bestimmtes suchen, werden Sie hier fündig.",
			title: "Online Fundbüro",
		},
		publicFacilities: {
			text: "Sie möchten eine Veranstaltung in einem unserer öffentlichen Gebäude durchführen? Dann reservieren Sie hier eine.",
			title: "Öffentliche Gebäude reservieren",
		},
		giftCards: {
			text: "Einkaufen fängt in der Heimat an! Nicht immer muss man in die große Stadt, um Erledigungen zu machen. Manchmal liegt das Gute doch so nah.",
			title: "Gutschein EIN.KAUF MIT HERZ",
		},
		swimming: {
			text: "Aufgrund der aktuellen Situation müssen Sie eine Reservierung vorweisen können, um eins unserer Schwimmbäder besuchen zu können.",
			title: "Schwimmbad Covid - 19 Reservierung",
		},
		reservierung: {
			text: "Vereinbaren Sie direkt online einen Termin mit dem zuständigen Sachbearbeiter.",
			title: "Bürgertermin anfragen",
		},
		title: "Wählen Sie Ihr Anliegen",
	},
	navigation: {
		buttons: {
			home: { description: "Startseite", title: "Willkommen<br />\nin der neuen App!" },
			info: { description: "Info", title: "Über diese App" },
			news: { description: "Aktuelles", title: "Aktuelle Nachrichten" },
			video: { description: "TV", title: "TV Wittlich-Land" },
		},
		routes: {
			fundbuero: "Online Fundbüro",
			publicFacilities: "Einrichtung Reservieren",
			termin: "Online Terminvereinbarung",
			videos: "TV Wittlich-Land",
			giftCard: "EIN.KAUF MIT HERZ",
			coronaSwimming: "Schwimmbadreservierung",
		},
		textSize: { big: "Groß", small: "Klein" },
	},
	publicFacilities: {
		buergerhaeuser: "Bürgerhäuser",
		dataEntry: {
			bookBtn: "Verbindlich Reservieren",
			bookingFailure: "Es ist ein Fehler bei der Reservierung aufgetreten",
			bookingHeading: "Überprüfen Sie Ihre Reservierung",
			bookingSuccess: "Reservierung war erfolgreich",
			confirmBtn: "Buchung fortsetzen",
			facilityName: "Name der Einrichtung",
			personalData: "Persönliche Daten",
			place: "Ort",
			reservationDate: "Datum der Reservierung",
			reservationHeading: "Reservierungsübersicht",
			reservationInfos: "Reservierungsinformationen",
		},
		dateEntry: { confirmBtn: "Datum bestätigen" },
		facilitiesList: {
			additionalInfo: {
				email: "Email",
				linkSatzung: "Link zur Satzung",
				location: "Ort",
				numPKW: "PKW Stellplätze",
				numPersonIn: "Anzahl an Sitzplätzen",
				persNumStuhl: "Sitzplätze (Stuhlreihen)",
				persNumTisch: "Sitzplätze (Tischreihen)",
				person: "Ansprechperson",
				phone: "Telefonnummer",
				place: "Standort",
			},
			datePrompt: {
				changeDate: "Das Datum ändern",
				continue: "Reservierung fortsetzen",
				dateQuestion: "Möchten Sie für dieses Datum die Einrichtung buchen?",
				selectedDate: "Sie haben im vorherigen Schritt das folgende Datum ausgewählt:",
			},
			equipment: "Ausstattung",
			facilityInfo: "Informationen zur Räumlichkeit",
			headings: { contact: "Kontakt", more: "Sonstiges" },
			icons: {
				accessible: "Barrierefrei",
				electricity: "Stromanschluss verfügbar",
				elevator: "Aufzug vorhanden",
				engine: "Stromgenerator erlaubt",
				fireplace: "Feuerstelle",
				kitchen: "Kochmöglichkeit",
				mobileNetwork: "Mobiles Netz vorhanden",
				outdoorTable: "Sitzmöglichkeiten draußen",
				sink: "Spülmöglichkeit",
				stage: "Bühne vorhanden",
				toilets: "Toiletten vorhanden",
			},
			misc: "Sonstiges",
			placeHeading: "Ort der Räumlichkeit",
			reserveBtn: "Reservieren",
		},
		schutzhuetten: "Schutzhütten",
		selection: {
			date: "Datumsauswahl",
			dateText: "Finden Sie eine Einrichtung passend zu Ihrem Datum",
			location: "Ortsauswahl",
			locationText: "Suchen Sie eine Einrichtung unabhängig vom Datum",
			title: "Suchen Sie ein Gebäude oder eine Schutzhütte",
		},
	},
	termin: {
		confirmation: {
			additionalInfo: "Informationen zu ihrer Anfrage",
			importantInfo: {
				text: 'Bitte beachten Sie, dass dies nur eine <span className="bold">Terminanfrage</span> ist. Erst nachdem diese von einem Sachbearbeiter beantwortet wurde, können Sie den Termin wahrnehmen.',
				title: "!! Achtung !!",
			},
			response: { error: "Es ist ein Fehler bei der Buchung aufgetreten", success: "Buchung war erfolgreich" },
			sendButton: "Terminanfrage senden",
			serviceInfo: {
				department: "Name der Abteilung",
				service: "Name des Services",
				timeSlot: "Termin",
				title: "Ausgewählter Service",
			},
			title: "Terminübersicht",
		},
		dataEntry: { submit: "Anfrage fortsetzen" },
		timeSlot: {
			availableTimeSlots: "Verfügbare Termine",
			noSlotsAvailable: "Keine Termine verfügbar",
			selectDate: "Wählen Sie ein Datum aus",
			slotsAvailable: "Termine verfügbar",
		},
	},
	giftCards: {
		confirmation: {
			response: {
				error: "Es ist ein Fehler bei der Gutscheinbestellung aufgetreten",
				success:
					"Ihre Gutscheinbestellung wurde erfolgreich bearbeitet. Bitte folgen Sie den Anweisungen, die wir Ihnen per Email geschickt haben.",
			},
		},
	},

	corona: {
		submit: "Reservieren",
		inputStep1: {
			title: "Anzahl der Haushalte",
		},
		inputStep2: {
			first: "Erster",
			second: "Zweiter",
			household: "Haushalt",
			num: "Anzahl der Anwohner dieses Haushalts",
			nameOf: "Name des",
			resident: "Anwohners",
		},

		response: {
			error: "Es ist ein Fehler bei der Schwimmbadreservierung aufgetreten",
			success: "Vielen Dank für Ihre Schwimmbadreservierung. Sie erhalten per Email den notwendigen QR - Code.",
		},
	},
};

export default TRANSLATIONS;
