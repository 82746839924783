import React, { Component } from "react";
import "./GiftCards.scss";
import { Button, Dialog } from "@onedash/tools";
import RouterHistory from "../../Helper/RouterHistory";
import Logo from "./logo.jpg";
import Utils from "../../Helper/Utils";

interface GiftCardsProps {}

class GiftCards extends Component<GiftCardsProps> {
	dialog = React.createRef<Dialog>();
	componentDidMount() {
		Utils.setBackPath("/");
	}

	render() {
		return (
			<div className="gift-cards">
				<img src={Logo} alt="EIN.KAUF MIT HERZ Logo" />
				<h2>Allgemein</h2>
				<p>
					Einkaufen fängt in der Heimat an! Nicht immer muss man in die große Stadt, um Erledigungen zu
					machen. Manchmal liegt das Gute doch so nah.
				</p>
				<p>
					So bietet die Verbandsgemeinde Wittlich-Land eine große Vielfalt an klassischen
					Einzelhandelsgeschäften, aber auch Dienstleistungsunternehmen, freie Berufe sowie Gastronomie-,
					Hotelerie- und Handwerksbetriebe sind gegenwärtig angesiedelt. Egal ob Metzgerei, Gärtnerei, Friseur
					oder Apotheke – hier findet man Qualität direkt vor der Haustür.
				</p>
				<Button onClick={() => RouterHistory.push("/online-gutscheine/submit")}>Gutschein erwerben</Button>
				<h2>Für Gutscheininhaber/innen</h2>
				<p>
					Den Gutschein können Sie in allen teilnehmenden Betrieben problemlos einlösen. Eine Übersicht aller
					Betriebe, die bei "EIN.KAUF MIT HERZ" mitmachen, finden Sie{" "}
					<a
						rel="noopener noreferrer"
						target="_blank"
						href="https://www.vg-wittlich-land.de/vg_wittlich_land/Aktuelles/Gutschein%20EIN.KAUF%20MIT%20HERZ/Teilnehmende%20Betriebe/">
						HIER.
					</a>
				</p>
			</div>
		);
	}
}

export default GiftCards;
