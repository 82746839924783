import React, { Component } from "react";
import "./GiftCards.scss";
import { Form, Input, Boolean, Dialog, Spinner } from "@onedash/tools";
import { t } from "../../Helper/TranslationHandler";
import Utils from "../../Helper/Utils";
import PrivacyText from "../../Components/Utils/PrivacyText";
import { BackendInterface } from "../../Helper/BackendInterface";
import RouterHistory from "../../Helper/RouterHistory";

interface GiftCardSubmitProps {}

interface GroupedGiftCard {
	price: number;
	amount: number;
}

class GiftCardSubmit extends Component<GiftCardSubmitProps> {
	inputForm = React.createRef<Form>();
	serverResponseDialog = React.createRef<Dialog>();
	state = {
		step: 1,
		giftCards: [] as number[],
		customAmount: false,
		groupedGiftCards: [] as GroupedGiftCard[],
		serverResponse: undefined as undefined | { type: "S" | "E" },
	};

	componentDidMount() {
		Utils.setBackPath("/online-gutscheine");
	}

	addGiftCardAmount = (amount: number, customAmount = false) => {
		const giftCards = this.state.giftCards;

		giftCards.push(amount);
		let step = this.state.step;
		if (step === 1) {
			step = 2;
		}
		this.groupGiftCards(giftCards);
		this.setState({ step, giftCards, customAmount });
	};

	groupGiftCards = (giftCards: number[]) => {
		const groupedGiftCards: GroupedGiftCard[] = [];
		giftCards.forEach((gc) => {
			const card = groupedGiftCards.find((x) => x.price === gc);
			if (card) {
				card.amount = card.amount + 1;
			} else {
				groupedGiftCards.push({
					amount: 1,
					price: gc,
				});
			}
		});
		this.setState({ groupedGiftCards }, () => {
			setTimeout(() => {
				if (this.inputForm.current) this.inputForm.current.resetValidation();
			}, 10);
		});
	};

	formChange = (_values: any, _form: Form, valid: boolean) => {
		if (valid === true) {
			this.setState({
				step: 3,
			});
		}
	};

	removeGiftCardAmount = (gc: GroupedGiftCard) => {
		const giftCards = this.state.giftCards;
		const index = giftCards.findIndex((x) => x === gc.price);
		if (index !== -1) {
			giftCards.splice(index, 1);
			this.groupGiftCards(giftCards);
			this.setState({ giftCards });

			if (giftCards.length === 0) {
				this.setState({
					step: 1,
				});
			}
		}
	};

	toPrice = (price: number) => String(price.toFixed(2).replace(".", ",") + "€");

	buyGiftCards = async (values: any, form: Form) => {
		if (this.serverResponseDialog.current) {
			this.serverResponseDialog.current.show();
		}
		const data = {
			values: { giftCards: this.state.groupedGiftCards },
			personalData: {
				name: `${values.firstName} ${values.lastName}`,
				email: values.email,
				address: values.address,
				plz: values.plz,
				place: values.location,
			},
		};
		BackendInterface.post(`/form/submit/giftCard`, data)
			.then((res) => {
				if (res.data.type === "S") {
					this.setState({
						serverResponse: {
							type: "S",
						},
					});
				} else {
					this.setState({
						serverResponse: {
							type: "E",
						},
					});
				}
			})
			.catch((ex) => {
				console.error(ex);
				this.setState({
					serverResponse: {
						type: "E",
					},
				});
			});
	};
	render() {
		return (
			<div className="gift-cards-submit">
				<Form
					ref={this.inputForm}
					className={`steps step-${this.state.step}`}
					submitText="Gutschein erwerben"
					onSubmit={this.buyGiftCards}
					onChange={this.formChange}
					validateOnSubmit>
					<div className="step">
						<h2>1. Gutscheinhöhe</h2>
						<p>Wählen Sie den gewünschten Gutscheinbetrag</p>
						<div className="cards">
							{giftCards.map((gc, index) => (
								<button key={index} onClick={() => this.addGiftCardAmount(gc)}>
									{this.toPrice(gc)}
								</button>
							))}
							{/*
							<button
								className={this.state.customAmount === true ? "active" : ""}
								onClick={() => this.setState({ customAmount: true })}>
								Eigener Betrag
							</button>
							{this.state.customAmount === true && (
								<Input
									name="amount"
									type="number"
									value={this.state.giftCardAmount}
									onChange={(e) => this.setGiftCardAmount(Number(e.value), true)}
									label="Gutscheinbetrag [EUR]"
									required
								/>
							)}*/}
						</div>
						{this.state.giftCards.length > 0 && (
							<div className="cart">
								<h3>Ihre ausgewählten Gutscheine</h3>
								<ul>
									{this.state.groupedGiftCards.map((gc, i) => (
										<li key={i}>
											<div className="price">
												<p>Gutschein - {this.toPrice(gc.price)}</p>
												<button onClick={() => this.removeGiftCardAmount(gc)}>Entfernen</button>
											</div>
											<p className="amount">{gc.amount}</p>
											<p className="total-price">{this.toPrice(gc.amount * gc.price)}</p>
										</li>
									))}
								</ul>
								<div className="total">
									<p>{this.toPrice(this.state.giftCards.reduce((sum, x) => sum + x))}</p>
								</div>
							</div>
						)}
					</div>
					<div className="step">
						<h2>2. Persönliche Daten</h2>

						<Input
							placeholder={t("dataEntry.firstName.placeholder")}
							name="firstName"
							label={t("dataEntry.firstName.label")}
							autoComplete="given-name"
							required
						/>
						<Input
							placeholder={t("dataEntry.lastName.placeholder")}
							name="lastName"
							label={t("dataEntry.lastName.label")}
							autoComplete="family-name"
							required
						/>
						<Input
							placeholder={t("dataEntry.email.placeholder")}
							name="email"
							label={t("dataEntry.email.label")}
							type="email"
							required
							autoComplete="email"
						/>
						<Input
							placeholder={t("dataEntry.address.placeholder")}
							label={t("dataEntry.address.label")}
							name="address"
							autoComplete="street-address"
							required
						/>
						<Input
							placeholder={t("dataEntry.plz.placeholder")}
							name="plz"
							label={t("dataEntry.plz.label")}
							required
							autoComplete="postal-code"
						/>
						<Input
							placeholder={t("dataEntry.location.placeholder")}
							name="location"
							autoComplete="address-level2"
							label={t("dataEntry.location.label")}
							required
						/>
					</div>
					{this.state.step === 3 && (
						<div className="step">
							<h2>3. Bestätigung</h2>
							<p>
								Sie erhalten nach der Bestellung eine Email mit den Zahlungsinformationen. Bitte
								überweisen Sie den gewählten Betrag von{" "}
								<span className="bold">
									{this.toPrice(this.state.giftCards.reduce((sum, x) => sum + x))}
								</span>{" "}
								auf das angegebene Konto.
							</p>
							<p>
								Sobald die Zahlung bei uns eingetroffen ist, werden wir Ihnen den Gutschein zu der oben
								angegebenen Adresse zuschicken.
							</p>
							<Boolean name="privacy-read" required className="onedash-switch-container">
								<PrivacyText />
							</Boolean>
						</div>
					)}
				</Form>

				<Dialog
					closeable={false}
					isOpen={false}
					ref={this.serverResponseDialog}
					className="no-padding data-proceed">
					{this.state.serverResponse !== undefined && (
						<>
							<div className={this.state.serverResponse?.type === "S" ? "success" : "hidden"}>
								<svg
									id="successAnimation"
									className={this.state.serverResponse?.type === "S" ? "animated" : ""}
									xmlns="http://www.w3.org/2000/svg"
									width="110"
									height="110"
									viewBox="0 0 70 70">
									<path
										id="successAnimationResult"
										fill="#D8D8D8"
										d="M35,60 C21.1928813,60 10,48.8071187 10,35 C10,21.1928813 21.1928813,10 35,10 C48.8071187,10 60,21.1928813 60,35 C60,48.8071187 48.8071187,60 35,60 Z M23.6332378,33.2260427 L22.3667622,34.7739573 L34.1433655,44.40936 L47.776114,27.6305926 L46.223886,26.3694074 L33.8566345,41.59064 L23.6332378,33.2260427 Z"
									/>
									<circle
										id="successAnimationCircle"
										cx="35"
										cy="35"
										r="24"
										stroke="#979797"
										strokeWidth="2"
										strokeLinecap="round"
										fill="transparent"
									/>
									<polyline
										id="successAnimationCheck"
										stroke="#979797"
										strokeWidth="2"
										points="23 34 34 43 47 27"
										fill="transparent"
									/>
								</svg>
							</div>
							<div className={this.state.serverResponse.type === "E" ? "error" : "hidden"}>
								<svg
									version="1.1"
									id="errorAnimation"
									className={this.state.serverResponse.type === "E" ? "animated" : ""}
									xmlns="http://www.w3.org/2000/svg"
									width="110"
									height="110"
									viewBox="0 0 70 70">
									<path
										id="errorAnimationResult"
										fill="#D8D8D8"
										d="M35,9.3C21.1,9.3,9.9,20.5,9.9,34.4c0,13.9,11.2,25.1,25.1,25.1c13.9,0,25.1-11.2,25.1-25.1
								C60.1,20.5,48.8,9.3,35,9.3z M44.2,42.2l-1.3,1.3L35,35.7l-7.8,7.8l-1.3-1.3l7.8-7.8l-7.8-7.8l1.3-1.3L35,33l7.8-7.8l1.3,1.3
								l-7.8,7.8L44.2,42.2z"
									/>
									<circle
										id="errorAnimationCircle"
										stroke="#979797"
										strokeWidth="2"
										strokeLinecap="round"
										fill="transparent"
										cx="35"
										cy="34.4"
										r="24"
									/>
									<polyline
										id="errorAnimationX"
										stroke="#979797"
										strokeWidth="2"
										fill="transparent"
										d="M36.3,34.4l7.8,7.8l-1.3,1.3L35,35.7l-7.8,7.8l-1.3-1.3l7.8-7.8l-7.8-7.8l1.3-1.3L35,33l7.8-7.8l1.3,1.3
								L36.3,34.4z"
									/>
								</svg>
							</div>
							<div className={!this.state.serverResponse.type ? "hidden" : "response-message-title"}>
								{this.state.serverResponse.type === "E" && (
									<h2>{t("giftCards.confirmation.response.error")}</h2>
								)}
								{this.state.serverResponse.type === "S" && (
									<h2>{t("giftCards.confirmation.response.success")}</h2>
								)}
							</div>
							<div className={!this.state.serverResponse.type ? "hidden" : "btn-wrapper"}>
								<button className="redirect-to-home" onClick={() => RouterHistory.push("/")}>
									{t("app.backToHome")}
								</button>
							</div>
						</>
					)}
					{!this.state.serverResponse && <Spinner defaultVisible />}
				</Dialog>
			</div>
		);
	}
}
const giftCards = [5, 10, 15, 20, 25, 30];
export default GiftCardSubmit;
