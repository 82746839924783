import React, { Component } from "react";
import Icon from "../../Resources/Icons/Icon";
import Utils from "../../Helper/Utils";
import RouterHistory from "../../Helper/RouterHistory";
import { DepartmentService } from "../../Resources/Generated/gql-types";

export interface DepartmentProps {
	title: string;
	description: string;
	services: DepartmentService[];
}

class Department extends Component<DepartmentProps> {
	redirectToTimeSlotSelection = (category: DepartmentService) => {
		sessionStorage.setItem("category", JSON.stringify(category));
		RouterHistory.push("/terminvereinbarung/time-slot-selection/" + category.id);
	};
	componentDidMount() {
		Utils.setBackPath("/");
	}

	render() {
		return (
			<div
				className="department-wrapper"
				onClick={(e: any) => {
					e.currentTarget.classList.toggle("active");
				}}>
				<div className="department">
					<div>
						<div className="department-title">{this.props.title}</div>
						<div className="department-description">{this.props.description}</div>
					</div>
					<div className="department-icon">
						<Icon name="back" color="white" size={25} />
					</div>
				</div>
				<div className="category-list">
					{this.props.services.map((service) => (
						<div
							key={service.id}
							onClick={() => this.redirectToTimeSlotSelection(service)}
							className="category">
							<div className="category-title">{service.name}</div>
							<div className="category-icon">
								<Icon name="back" color="white" size={18} />
							</div>
						</div>
					))}
				</div>
			</div>
		);
	}
}

export default Department;
