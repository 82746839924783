import React, { Component } from "react";
import "./PublicFacilitiesSelection.scss";
import Utils from "../../Helper/Utils";
import RouterHistory from "../../Helper/RouterHistory";
import PublicFacilityDatePicker from "./PublicFacilityDatePicker";
import { t } from "../../Helper/TranslationHandler";
import Icon from "../../Resources/Icons/Icon";

export interface PublicFacilitiesSelectionProps {}

class PublicFacilitiesSelection extends Component<PublicFacilitiesSelectionProps> {
	datePickerDialog = React.createRef<PublicFacilityDatePicker>();

	componentDidMount() {
		Utils.setBackPath("/");
	}

	redirectToFacilityList = (dateString?: any) => {
		if (typeof dateString !== "string" && typeof dateString !== "number") dateString = "";
		RouterHistory.push("/gebaeude-reservieren/facility-list/" + dateString);
	};

	render() {
		return (
			<div className="public-facilities-selection">
				<h2>{t("publicFacilities.selection.title")}</h2>
				<div>
					<ul>
						<li
							onClick={() => {
								if (this.datePickerDialog.current) this.datePickerDialog.current.open();
							}}>
							<div className="icon">
								<Icon color="white" name="calendar" size={25} />
							</div>
							<div>
								<div className="title">{t("publicFacilities.selection.date")}</div>
								<div className="text">{t("publicFacilities.selection.dateText")}</div>
							</div>
						</li>

						<li onClick={this.redirectToFacilityList}>
							<div className="icon">
								<Icon color="white" name="list" size={25} />
							</div>
							<div>
								<div className="title">{t("publicFacilities.selection.location")}</div>
								<div className="text">{t("publicFacilities.selection.locationText")}</div>
							</div>
						</li>

						<PublicFacilityDatePicker
							ref={this.datePickerDialog}
							onDateRangeSelected={this.redirectToFacilityList}
						/>
					</ul>
				</div>
			</div>
		);
	}
}

export default PublicFacilitiesSelection;
