import AXIOS from "axios";
import ENV from "./ENV";

export class BackendInterface {
	private static backendDataServer = ENV.default.apiURL;
	private static APIv2 = ENV.default.newAPI;

	private static checkPath = (path: string) => {
		if (path.charAt(0) !== "/") {
			path = "/" + path;
		}
		return path;
	};
	static get = async (path: string, newAPI = false) => {
		path = BackendInterface.checkPath(path);
		let url = BackendInterface.backendDataServer + path;
		if (newAPI === true) {
			url = BackendInterface.APIv2 + path;
		}
		return (await AXIOS.get(url)).data;
	};
	static getDataByUrl = (path: string, callback: Function) => {
		BackendInterface.callRequest(path, callback);
	};

	static batchGet = (pathes: string[], callback: (responses: any[]) => void) => {
		let finished = pathes.length;
		let responses: any = [];
		pathes.forEach((path) => {
			BackendInterface.callRequest(path, (data: any) => {
				responses[pathes.indexOf(path)] = data;
				finished--;

				if (finished === 0) {
					callback(responses);
				}
			});
		});
	};

	private static callRequest(path: string, callback: any) {
		path = BackendInterface.checkPath(path);
		AXIOS.get(BackendInterface.backendDataServer + path).then((response) => {
			if (response) {
				callback(response.data);
			} else {
				callback({});
			}
		});
	}

	static postData = (path: string, postData: any, callback: Function = () => {}) => {
		path = BackendInterface.checkPath(path);
		AXIOS.post(BackendInterface.backendDataServer + path, postData).then(function (response) {
			try {
				if (response.data.type === "E") {
					console.error(response.data);
					callback(response.data);
				} else {
					callback(response.data);
				}
			} catch (ex) {}
		});
	};
	static post = (path: string, postData: any, newAPI = true) => {
		path = BackendInterface.checkPath(path);
		let url = BackendInterface.APIv2 + path;
		if (newAPI === false) {
			url = BackendInterface.backendDataServer + path;
		}
		return AXIOS.post(url, postData);
	};

	static buildInlinePath = (path: string) => {
		path = BackendInterface.checkPath(path);
		return BackendInterface.backendDataServer + path;
	};

	static uploadFiles = (path: string, files: File[], data: any, callback: Function = () => {}) => {
		let formData = new FormData();
		files.forEach((file) => formData.append("image", file));
		formData.append("data", data);
		path = BackendInterface.checkPath(path);

		AXIOS.post(BackendInterface.backendDataServer + path, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		}).then(function (response) {
			try {
				if (response.data.type === "E") {
					console.error(response.data);
					callback(response.data);
				} else {
					callback(response.data);
				}
			} catch (ex) {}
		});
	};
}
