export default function MainReducer(state = {} as any, action: any) {
	switch (action.type) {
		case "SET_BACK_BUTTON_PATH": {
			state.backPath = action.path;
			return state;
		}
		case "UPDATE_AVAILABLE": {
			state.updateAvailable = true;
			return state;
		}

		case "SET_SWIMMING_HEADER": {
			state.headerSwimmingPool = action.swimmingPool;
			return state;
		}
		case "APP_INSTALL_AVAILABLE": {
			state.appInstallAvailable = true;
			return state;
		}
		case "SAVE_DEPARTMENTDATA": {
			state.depData = action.depData;
			return state;
		}
		case "NETWORK_STATE": {
			state.online = action.online;
			return state;
		}
		case "SHOW_TEXT_SIZE": {
			state.textSize = true;
			setTimeout(() => {
				state.textSize = false;
			}, 1500);
			return state;
		}
		default:
			return state;
	}
}
