import * as React from "react";
import Cookies from "js-cookie";
import { Boolean } from "@onedash/tools";
export interface PrivacyProps {
	inline?: boolean;
}

const Privacy: React.SFC<PrivacyProps> = ({ inline }) => {
	const toggleCookies = (e: { value: any; name: string }) => {
		if (e.value === false) {
			Object.keys(Cookies.get()).forEach(function (cookie) {
				Cookies.remove(cookie);
			});
			Cookies.set("settings-cookie", "false");
			setTimeout(() => {
				window.location.reload();
			}, 300);
		} else {
			Cookies.set("settings-cookie", "true");
		}
	};
	return (
		<div className={inline === true ? "privacy about-text" : "privacy about-text standalone"}>
			{!inline && <h2>Datenschutz&shy;erklärung</h2>}
			<h3>Kurzfassung</h3>
			<p>
				Wir verarbeiten personenbezogene Daten der Nutzer*innen dieser Anwendung nur unter Einhaltung der
				einschlägigen Datenschutzbestimmungen. Das bedeutet: Diese Daten werden grundsätzlich nur bei Vorliegen
				einer gesetzlichen Erlaubnis verarbeitet.
			</p>
			<p>
				Jegliche Daten, die erhoben werden, werden <span className="bold">nicht</span> an Drittanbieter
				weitergegeben. Sie dienen nur der jewails explizit angegebenen Funktion.
			</p>
			<p>Wir treffen Sicherheitsmaßnahmen, um Ihre Daten zu schützen.</p>
			<p>
				Damit Sie diese Anwendung überhaupt ansehen können, wird Ihre IP-Adresse vom Webserver für die Dauer des
				Besuchs verarbeitet. Der Webserver selbst wird von dem deutschen Unternehmen Netcup bereitgestellt.
			</p>
			<p>
				Diese Seite verwendet (sehr wenige) Cookies um Benutzereinstellungen (wie z.B. die Schriftgöße) zu
				speichern. Falls Sie Ihre Einstellung ändern möchten, haben Sie hier die Möglichkeit:
				<br />
			</p>
			<Boolean
				className="cookie-privacy-toggle"
				name="set-cookies"
				onChange={toggleCookies}
				value={Cookies.get("settings-cookie") === "true"}>
				Wenn diese Option aktiviert ist, dann sind Sie damit einverstanden, dass wir Cookies auf der Seite
				einsetzen.
			</Boolean>
			<h3>Datenschutz</h3>
			<p>
				Die Betreiber dieser Seite nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre
				personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie
				dieser Datenschutzerklärung.
			</p>
			<p>
				Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf
				unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder E-Mail-Adressen) erhoben
				werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre
				ausdrückliche Zustimmung nicht an Dritte weitergegeben.
			</p>
			<p>
				Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail)
				Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
				nicht möglich.
			</p>
			<h4>Arten der verarbeiteten Daten</h4>
			<ul>
				<li>Bestandsdaten (z.B. Personen-Stammdaten, Namen oder Adressen)</li>
				<li>Kontaktdaten (z.B. E-Mail, Telefonnummern)</li>
				<li>Inhaltsdaten (z.B. Texteingaben, Fotografien)</li>
				<li>Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen)</li>
			</ul>
			<h3>Geltungsbereich</h3>
			<p>
				Diese Datenschutzerklärung gilt für das Internet-Angebot www.vg-wittlich-land.de der Verbandsgemeinde
				Wittlich-Land und die dort angebotenen eigenen Inhalte. Für Inhalte anderer Anbieter, auf die z.B. über
				Links verwiesen wird, gelten die dortigen Bestimmungen. Insbesondere sind diese gemäß § 7 Abs. 1
				Telemediengesetz (TMG) für eigene Inhalte verantwortlich.
			</p>
			<h3>Verantwortlichkeit</h3>
			<p>
				Für die Webseite verantwortlich im Sinne der Datenschutz-Grundverordnung und des
				Landesdatenschutzgesetzes Rheinland-Pfalz ist die:
			</p>
			<p>Verbandsgemeinde Wittlich-Land Kurfürstenstraße 1 54516 Wittlich</p>
			<p>
				Telefon: +49 6571 / 107 - 0<br />
				Telefax: +49 6571 / 107 - 155
			</p>
			<p>
				E-Mail: <a href="mailto:info@vg-wittlich-land.de">info@vg-wittlich-land.de</a>
			</p>
			<h4>Datenschutzbeauftragter</h4>
			<p>
				Frank Brixius <br />
				Telefon: +49 6571 / 107 - 122
				<br />
				Kurfürstenstraße 1<br />
				54516 Wittlich
				<br />
				E-Mail: <a href="mailto:datenschutz@vg-wittlich-land.de">datenschutz@vg-wittlich-land.de</a>
			</p>
			<h3>Rechte der betroffenen Personen</h3>
			<p>
				Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob betreffende Daten verarbeitet werden und
				auf Auskunft über diese Daten sowie auf weitere Informationen und Kopie der Daten entsprechend den
				gesetzlichen Vorgaben.
			</p>
			<p>
				Sie haben entsprechend. den gesetzlichen Vorgaben das Recht, die Vervollständigung der Sie betreffenden
				Daten oder die Berichtigung der Sie betreffenden unrichtigen Daten zu verlangen.
			</p>
			<p>
				Sie haben nach Maßgabe der gesetzlichen Vorgaben das Recht zu verlangen, dass betreffende Daten
				unverzüglich gelöscht werden, bzw. alternativ nach Maßgabe der gesetzlichen Vorgaben eine Einschränkung
				der Verarbeitung der Daten zu verlangen.
			</p>
			<p>
				Sie haben das Recht zu verlangen, dass die Sie betreffenden Daten, die Sie uns bereitgestellt haben nach
				Maßgabe der gesetzlichen Vorgaben zu erhalten und deren Übermittlung an andere Verantwortliche zu
				fordern.
			</p>
			<p>
				Sie haben ferner nach Maßgabe der gesetzlichen Vorgaben das Recht, eine Beschwerde bei der zuständigen
				Aufsichtsbehörde einzureichen.
			</p>
			<h3>Server Log Files</h3>
			<p>
				Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log Files,
				die Ihr Browser beim Zugriff auf unsere Seiten automatisch an uns übermittelt. Dies sind:
			</p>
			<ul>
				<li>Dateiname</li>
				<li>Datum und Uhrzeit der Anforderung</li>
				<li>übermittelte Datenmenge</li>
				<li>Browsertyp/ Browserversion</li>
				<li>verwendetes Betriebssystem</li>
				<li>Referrer URL</li>
				<li>Zugriffsstatus (übermittelt, nicht gefunden etc.)</li>
			</ul>
			<p>
				Diese Daten sind nicht bestimmten Personen zuordbar. Eine Zusammenführung dieser Daten mit anderen
				Datenquellen wird nicht vorgenommen. Wir behalten uns vor, diese Daten nachträglich zu prüfen, wenn uns
				konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt werden.
			</p>
			<h3>Cookies</h3>
			<p>
				Die Anwendung verwendet teilweise so genannte Cookies. Cookies richten auf Ihrem Rechner keinen Schaden
				an und enthalten keine Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und
				sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr
				Browser speichert. Die von uns verwendeten Cookies sind so genannte „Session-Cookies“. In einem
				Session-Cookie wird eine zufällig erzeugte eindeutige Identifikationsnummer abgelegt, eine sogenannte
				Session-ID. Außerdem enthält ein Cookie die Angabe über seine Herkunft und die Speicherfrist. Diese
				Cookies können keine anderen Daten speichern und werden nach Ende Ihres Besuchs automatisch gelöscht.
				Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und
				Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell
				ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browser aktivieren. Bei der
				Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein. Bei der Nutzung
				fremder Inhalte über unsere Webseite (z.B. im Fall einer Verlinkung) können gegebenenfalls Cookies von
				Drittanbietern zum Einsatz kommen, ohne dass wir Sie ausdrücklich darauf hinweisen können.
			</p>
			<h3>Sicherheitsmaßnahmen</h3>
			<p>
				Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter Berücksichtigung des Stands der Technik, der
				Implementierungskosten und der Art, des Umfangs, der Umstände und der Zwecke der Verarbeitung sowie der
				unterschiedlichen Eintrittswahrscheinlichkeit und Schwere des Risikos für die Rechte und Freiheiten
				natürlicher Personen, geeignete technische und organisatorische Maßnahmen, um ein dem Risiko
				angemessenes Schutzniveau zu gewährleisten. Zu den Maßnahmen gehören insbesondere die Sicherung der
				Vertraulichkeit, Integrität und Verfügbarkeit von Daten durch Kontrolle des physischen Zugangs zu den
				Daten, als auch des sie betreffenden Zugriffs, der Eingabe, Weitergabe, der Sicherung der Verfügbarkeit
				und ihrer Trennung. Des Weiteren haben wir Verfahren eingerichtet, die eine Wahrnehmung von
				Betroffenenrechten, Löschung von Daten und Reaktion auf Gefährdung der Daten gewährleisten. Ferner
				berücksichtigen wir den Schutz personenbezogener Daten bereits bei der Entwicklung, bzw. Auswahl von
				Hardware, Software sowie Verfahren, entsprechend dem Prinzip des Datenschutzes durch Technikgestaltung
				und durch datenschutzfreundliche Voreinstellungen.
			</p>
			<h3>Kontaktaufnahme</h3>
			<p>
				Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular oder E-Mail ) werden die Angaben des Nutzers
				zur Bearbeitung der Kontaktanfrage und deren Abwicklung gem. Art. 6 Abs. 1 lit. b. (im Rahmen
				vertraglicher-/vorvertraglicher Beziehungen), Art. 6 Abs. 1 lit. f. (andere Anfragen) DSGVO verarbeitet.
				Die Angaben der Nutzer können in einem Customer-Relationship-Management System (CRMS) gespeichert
				werden. Wir löschen die Anfragen, sofern diese nicht mehr erforderlich sind. Wir überprüfen die
				Erforderlichkeit alle zwei Jahre; Ferner gelten die gesetzlichen Archivierungspflichten.
			</p>

			<p>
				<span className="bold">Auftragsverarbeiter:</span>
				<br />
				Für den Betrieb unserer Applikation „Mein Wittlich.Land“, den Empfang und die Weiterleitung von Anfragen
				über das Kontaktformular haben wir derzeit das Unternehmen OneDash., Burgstraße 19, 54470
				Bernkastel-Kues, als technischen Auftragsverarbeiter beauftragt.
			</p>
			<p>
				Ihre personenbezogenen Daten werden gemäß Art. 6 Abs. 1 b) DSGVO ausschließlich zur Abwicklung Ihrer
				Kursbuchung sowie gemäß Art. 6 Abs. 1 f) DSGVO zur Wahrung unserer berechtigten Interessen genutzt.
			</p>

			<h3>Verwendung von Youtube</h3>
			<p>
				Unsere Webseite nutzt Plugins der von Google betriebenen Seite YouTube. Betreiber der Seite ist YouTube,
				LLC, 901 Cherry Ave., San Bruno, CA 94066, USA. Wenn Sie eine unserer mit einem YouTube-Plugin
				ausgestatteten Seiten besuchen, wird eine Verbindung zu den Servern von YouTube hergestellt. Dabei wird
				dem YouTube-Server mitgeteilt, welche unserer Seiten Sie besucht haben.
			</p>
			<p>
				Wenn Sie in Ihrem YouTube-Account eingeloggt sind, ermöglichen Sie YouTube, Ihr Surfverhalten direkt
				Ihrem persönlichen Profil zuzuordnen. Dies können Sie verhindern, indem Sie sich aus Ihrem
				YouTube-Account ausloggen.
			</p>
			<p>
				Weitere Informationen zum Umgang von Nutzerdaten finden Sie in der Datenschutzerklärung von YouTube
				unter{" "}
				<a href="https://www.google.de/intl/de/policies/privacy">
					https://www.google.de/intl/de/policies/privacy
				</a>
				.
			</p>

			<h3>Google Fonts</h3>
			<p>
				Wir binden die Schriftarten ("Google Fonts") des Anbieters Google Ireland Limited, Gordon House, Barrow
				Street, Dublin 4, Irland, ein. Nach Angaben von Google werden die Daten der Nutzer allein zu Zwecken der
				Darstellung der Schriftarten im Browser der Nutzer verwendet. Die Einbindung erfolgt auf Grundlage
				unserer berechtigten Interessen an einer technisch sicheren, wartungsfreien und effizienten Nutzung von
				Schriftarten, deren einheitlicher Darstellung sowie Berücksichtigung möglicher lizenzrechtlicher
				Restriktionen für deren Einbindung. Datenschutzerklärung: www.google.com/policies/privacy/.
			</p>

			<h3>
				Ergänzende Daten&shy;schutz&shy;hinweise bezüglich der Corona Pandemie nach Artikel 13 und 14
				Daten&shy;schutz&shy;grund&shy;verordnung (DSGVO)
			</h3>

			<p>
				<span className="bold">
					1. Name und Kontaktdaten der für die Datenverarbeitung verantwortlichen Stelle
				</span>
			</p>
			<p>
				Verbandsgemeinde Wittlich-Land
				<br />
				Kurfürstenstraße 1
				<br />
				54516 Wittlich
				<br />
				info@vg-wittlich-land.de
			</p>

			<p>
				<span className="bold">2. Anschrift des Datenschutzbeauftragten</span>
			</p>
			<p>
				Verbandsgemeinde Wittlich-Land
				<br />
				Datenschutzbeauftragter
				<br />
				Kurfürstenstraße 1
				<br />
				54516 Wittlich
				<br />
				Datenschutz@vg-wittlich-land.de
				<br />
			</p>
			<p>
				<span className="bold">3. Kategorien von personenbezogenen Daten</span>
			</p>
			<p>
				Wenn Sie in unsere Verwaltung / Schwimmbad Manderscheid besuchen, erheben wir folgende Informationen von
				Ihnen: <br />
				Name, Vorname <br />
				Adresse <br />
				Telefonnummer und E-Mail-Adresse <br />
				Datum und Zeitpunkt des Beginnes und Ende des Besuchs <br />
				Sollten Sie mit der Erfassung dieser Daten nicht einverstanden sein, dürfen wir Ihnen keinen Zutritt
				gestatten.
			</p>

			<p>
				<span className="bold">4. Zweck und Rechtsgrundlage der Verarbeitung</span>
			</p>
			<p>
				Die Datenerhebung erfolgt zum Zweck der Ermöglichung einer Kontaktpersonennachverfolgung im Zusammenhang
				mit Covid-19. Rechtsgrundlage der Verarbeitung ist Art. 6 Abs. 1 c) DSGVO i. V. m.
				CoronaBekämpfungsverordnung Rheinland-Pfalz in der jeweils gültigen Fassung
			</p>
			<p>
				Zusätzlich verwenden wir Ihre Email - Adresse um Sie zu benachrichtigen, falls Sie Ihre Reservierung
				nicht wahrnehmen können. Dies kann beispielsweise der Fall sein, wenn das Bad witterungsbedingt
				schließen muss.
			</p>

			<p>
				<span className="bold">5. Übermittlung von Daten an Dritte</span>
			</p>
			<p>
				Eine Übermittlung der o.g. Daten erfolgt nur an das jeweils zuständige Gesundheitsamt bzw. die jeweils
				zuständige öffentliche Stelle zu den o.g. Zwecken. Ihre Daten werden ausschließlich auf Grundlage der
				oben genannten Rechtsgrundlagen an Dritte weitergegeben. Sie werden ausdrücklich nicht für Werbezwecke
				verwendet.
			</p>

			<p>
				<span className="bold">6. Dauer der Speicherung</span>
			</p>
			<p>
				Die Daten werden vier Wochen ab dem Zeitpunkt Ihres Besuchs bei uns gespeichert. Anschließend werden sie
				vernichtet.
			</p>

			<p>
				<span className="bold">7. Ihre Betroffenenrechte</span>
			</p>

			<p>Als Betroffener gemäß DSGVO haben Sie folgende Rechte:</p>
			<ul>
				<li>Recht auf Auskunft</li>
				<li>Recht auf Berichtigung</li>
				<li>Recht auf löschung oder auf einschränkung der verarbeitung</li>
				<li>Recht auf Beschwerde bei einer Aufsichtsbehörde</li>
			</ul>

			<p>
				Die für uns zuständige Aufsichtsbehörde ist: Der Landesbeauftragte für den Datenschutz und die
				Informationsfreiheit Rheinland-Pfalz
				<br />
				Postfach 30 40
				<br />
				55020 Mainz
				<br />
				Beachten Sie auch unsere Datenschutzerklärung auf unserer Website unter:
				<a href="https://www.vg-wittlich-land.de/vg_wittlich_land/Home/Datenschutzerkl%C3%A4rung/">
					https://www.vg-wittlich-land.de/vg_wittlich_land/Home/Datenschutzerkl%C3%A4rung/
				</a>
			</p>
		</div>
	);
};

export default Privacy;
