import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: string;
	String: string;
	Boolean: boolean;
	Int: number;
	Float: number;
	/** A JavaScript Timestamp number. It can easily parsed by a Date object. */
	Timestamp: any;
	/** The `Upload` scalar type represents a file upload. */
	Upload: any;
};

export type Absence = {
	__typename?: "Absence";
	id: Scalars["ID"];
	userID: Scalars["String"];
	timeslot: Timeslot;
	name: Scalars["String"];
	description?: Maybe<Scalars["String"]>;
};

export enum AccessModes {
	R = "r",
	W = "w",
	Rw = "rw",
}

export enum AppTypes {
	Gastro = "gastro",
	Vg = "vg",
	Website = "website",
	App = "app",
}

export type BookingDepartment = {
	__typename?: "BookingDepartment";
	id: Scalars["ID"];
	name: Scalars["String"];
	description?: Maybe<Scalars["String"]>;
};

export enum CacheControlScope {
	Public = "PUBLIC",
	Private = "PRIVATE",
}

export type CreateAbsence = {
	from: Scalars["Timestamp"];
	to: Scalars["Timestamp"];
	name: Scalars["String"];
	description?: Maybe<Scalars["String"]>;
	periodTo?: Maybe<Scalars["Timestamp"]>;
	periodRule?: Maybe<Scalars["String"]>;
};

export type DeletionPayload = {
	__typename?: "DeletionPayload";
	affectedRows: Scalars["Int"];
};

export type Department = {
	__typename?: "Department";
	id: Scalars["ID"];
	name: Scalars["String"];
	description?: Maybe<Scalars["String"]>;
	services: Array<DepartmentService>;
	users: Array<User>;
};

export type DepartmentBooking = {
	__typename?: "DepartmentBooking";
	id: Scalars["ID"];
	departmentService: DepartmentServiceBookings;
	timeslot: Timeslot;
	customer: PersonData;
	clerk?: Maybe<User>;
	state: DepartmentBookingState;
	identifier: Scalars["String"];
	created_at: Scalars["Timestamp"];
	updated_at: Scalars["Timestamp"];
};

export enum DepartmentBookingState {
	Accepted = "ACCEPTED",
	Declined = "DECLINED",
	Pending = "PENDING",
	DateSuggested = "DATE_SUGGESTED",
	Storno = "STORNO",
}

export type DepartmentService = {
	__typename?: "DepartmentService";
	id: Scalars["ID"];
	name: Scalars["String"];
	duration: Scalars["Int"];
	additional?: Maybe<Scalars["String"]>;
	departmentID: Scalars["ID"];
	users?: Maybe<Array<User>>;
};

export type DepartmentServiceBookings = {
	__typename?: "DepartmentServiceBookings";
	department: BookingDepartment;
	id: Scalars["ID"];
	name: Scalars["String"];
	duration: Scalars["Int"];
	additional?: Maybe<Scalars["String"]>;
};

export type Mutation = {
	__typename?: "Mutation";
	/** @deprecated Field no longer supported */
	_empty?: Maybe<Scalars["String"]>;
	/** Creates a new public vacation */
	createPublicVacation: PublicVacation;
	/** Updates a public vacation */
	updatePublicVacation: PublicVacation;
	/** Deletes a public vacation by it's id */
	deletePublicVacation: DeletionPayload;
	/**
	 * !!! Attention !!!
	 * Only for testing purposes!
	 * @deprecated Field no longer supported
	 */
	_failingTransaction: Scalars["Boolean"];
	/**
	 * !!! Attention !!!
	 * Only for testing purposes!
	 * @deprecated Field no longer supported
	 */
	_transaction: Scalars["Boolean"];
	/** Manages Admin Functions on Bookings */
	manageAdminDepartmentBooking: DepartmentBooking;
	/** Manages Public Functions on Bookings */
	cancelDepartmentBooking: DepartmentBooking;
	/** Create Bookings */
	createDepartmentBooking: DepartmentBooking;
	/** Creates a new Department */
	createDepartment: Department;
	/** Updates a Department */
	updateDepartment: Department;
	assignDepartmentAdmin: ObjectAccessAssignment;
	deassignDepartmentAdmin: DeletionPayload;
	/** Deletes a department by it's id */
	deleteDepartment: DeletionPayload;
	/** Creates a new Department Service */
	createDepartmentService: DepartmentService;
	/** Updates a Department Service */
	updateDepartmentService: DepartmentService;
	assignDepartmentServiceClerk: ObjectAccessAssignment;
	deassignDepartmentServiceClerk: DeletionPayload;
	/** Deletes a department service by it's id */
	deleteDepartmentService: DeletionPayload;
	/** Creates a new working hour */
	createWorkingHour: WorkingHour;
	/** Updates a working hour */
	updateWorkingHour: WorkingHour;
	/** Deletes a working hour by it's id */
	deleteWorkingHour: DeletionPayload;
	/** Creates a new Absence */
	createAbsence: Array<Maybe<Absence>>;
	/** Updates a Absence */
	updateAbsence: Absence;
	/** Deletes a Absence by it's id */
	deleteAbsence: DeletionPayload;
};

export type MutationCreatePublicVacationArgs = {
	vacation: UpdatePublicVation;
};

export type MutationUpdatePublicVacationArgs = {
	id: Scalars["ID"];
	vacation: UpdatePublicVation;
};

export type MutationDeletePublicVacationArgs = {
	id: Scalars["ID"];
};

export type MutationManageAdminDepartmentBookingArgs = {
	type: UpdateDepartmentAdminTypes;
	identifier: Scalars["String"];
	message?: Maybe<Scalars["String"]>;
};

export type MutationCancelDepartmentBookingArgs = {
	identifier: Scalars["String"];
};

export type MutationCreateDepartmentBookingArgs = {
	departmentBooking: UpdateDepartmentBooking;
	timeslotGenerationStart?: Maybe<Scalars["Timestamp"]>;
};

export type MutationCreateDepartmentArgs = {
	department: UpdateDepartment;
};

export type MutationUpdateDepartmentArgs = {
	id: Scalars["ID"];
	department: UpdateDepartment;
};

export type MutationAssignDepartmentAdminArgs = {
	departmentId: Scalars["ID"];
	userId: Scalars["ID"];
};

export type MutationDeassignDepartmentAdminArgs = {
	departmentId: Scalars["ID"];
	userId: Scalars["ID"];
};

export type MutationDeleteDepartmentArgs = {
	id: Scalars["ID"];
};

export type MutationCreateDepartmentServiceArgs = {
	departmentID: Scalars["ID"];
	service: UpdateDepartmentService;
};

export type MutationUpdateDepartmentServiceArgs = {
	id: Scalars["ID"];
	service: UpdateDepartmentService;
};

export type MutationAssignDepartmentServiceClerkArgs = {
	serviceId: Scalars["ID"];
	userId: Scalars["ID"];
};

export type MutationDeassignDepartmentServiceClerkArgs = {
	serviceId: Scalars["ID"];
	userId: Scalars["ID"];
};

export type MutationDeleteDepartmentServiceArgs = {
	id: Scalars["ID"];
};

export type MutationCreateWorkingHourArgs = {
	workingHour: UpdateWorkingHour;
};

export type MutationUpdateWorkingHourArgs = {
	id: Scalars["ID"];
	workingHour: UpdateWorkingHour;
};

export type MutationDeleteWorkingHourArgs = {
	id: Scalars["ID"];
};

export type MutationCreateAbsenceArgs = {
	absence: CreateAbsence;
};

export type MutationUpdateAbsenceArgs = {
	id: Scalars["ID"];
	absence: UpdateAbsence;
};

export type MutationDeleteAbsenceArgs = {
	id: Scalars["ID"];
	deletePeriod?: Maybe<Scalars["Boolean"]>;
};

export type ObjectAccessAssignment = {
	__typename?: "ObjectAccessAssignment";
	userId: Scalars["String"];
	objectID: Scalars["String"];
	mode: AccessModes;
	tableType: TableType;
	tableName: ObjectAccessTable;
};

export enum ObjectAccessTable {
	Lodgings = "Lodgings",
	Departments = "Departments",
	DepartmentServices = "DepartmentServices",
}

export type PersonData = {
	__typename?: "PersonData";
	id: Scalars["ID"];
	firstName: Scalars["String"];
	lastName: Scalars["String"];
	email: Scalars["String"];
	phone?: Maybe<Scalars["String"]>;
	street?: Maybe<Scalars["String"]>;
	houseNumber?: Maybe<Scalars["String"]>;
	plz?: Maybe<Scalars["String"]>;
	city?: Maybe<Scalars["String"]>;
	country?: Maybe<Scalars["String"]>;
	imageURL?: Maybe<Scalars["String"]>;
};

export type PublicVacation = {
	__typename?: "PublicVacation";
	id: Scalars["ID"];
	name: Scalars["String"];
	timeslot: Timeslot;
};

export type Query = {
	__typename?: "Query";
	/** @deprecated Field no longer supported */
	_empty?: Maybe<Scalars["String"]>;
	publicVacations: Array<PublicVacation>;
	version: Scalars["String"];
	/**
	 * !!! Attention !!!
	 * Only for testing purposes!
	 * @deprecated Field no longer supported
	 */
	_secret: Scalars["Boolean"];
	/**
	 * !!! Attention !!!
	 * Only for testing purposes!
	 * @deprecated Field no longer supported
	 */
	_secretPerm: Scalars["Boolean"];
	/**
	 * !!! Attention !!!
	 * Only for testing purposes!
	 * @deprecated Field no longer supported
	 */
	_appID: Scalars["Boolean"];
	/**
	 * !!! Attention !!!
	 * Only for testing purposes!
	 * @deprecated Field no longer supported
	 */
	_appIDType: Scalars["Boolean"];
	/** Check whether the user has access for a object. */
	checkAccess: Scalars["Boolean"];
	/** Returns a list of users which match either by their name, email or id */
	findUser: Array<User>;
	departments: Array<Department>;
	departmentServiceTimeslots: Array<TemporaryTimeslot>;
	departmentBookings: Array<DepartmentBooking>;
	departmentBooking: DepartmentBooking;
	workingHours: Array<WorkingHour>;
	userAbsences: Array<Absence>;
	userAbsence?: Maybe<Absence>;
};

export type QueryPublicVacationsArgs = {
	from: Scalars["Timestamp"];
	to: Scalars["Timestamp"];
};

export type QueryCheckAccessArgs = {
	tableType: TableType;
	tableName: ObjectAccessTable;
	objectID: Scalars["ID"];
	mode: AccessModes;
};

export type QueryFindUserArgs = {
	identifier: Scalars["String"];
};

export type QueryDepartmentServiceTimeslotsArgs = {
	serviceID: Scalars["ID"];
	timeslotGenerationStart?: Maybe<Scalars["Timestamp"]>;
};

export type QueryDepartmentBookingsArgs = {
	from: Scalars["Timestamp"];
	to: Scalars["Timestamp"];
};

export type QueryDepartmentBookingArgs = {
	id: Scalars["ID"];
};

export type QueryUserAbsencesArgs = {
	from: Scalars["Timestamp"];
	to: Scalars["Timestamp"];
};

export type QueryUserAbsenceArgs = {
	id: Scalars["ID"];
};

export type Subscription = {
	__typename?: "Subscription";
	/** @deprecated Field no longer supported */
	_empty?: Maybe<Scalars["String"]>;
	publicVacations: PublicVacation;
};

export enum TableType {
	Mysql = "MYSQL",
	Mongo = "MONGO",
}

export type TemporaryTimeslot = {
	__typename?: "TemporaryTimeslot";
	from: Scalars["Timestamp"];
	to: Scalars["Timestamp"];
};

export type Timeslot = {
	__typename?: "Timeslot";
	id: Scalars["ID"];
	from: Scalars["Timestamp"];
	to: Scalars["Timestamp"];
	periodFrom?: Maybe<Scalars["Timestamp"]>;
	periodTo?: Maybe<Scalars["Timestamp"]>;
	periodId?: Maybe<Scalars["ID"]>;
	/**
	 * Period repeating rule.
	 * Frequency[(frequency options)]+Interval[+Days]
	 *
	 * Frequencies:
	 * - D (Daily)
	 * - W (Weekly)
	 * - M (Monthly)
	 * - Y (Yearly)
	 * - P (Public Holiday [Works like day])
	 * - H (School Holiday [Works like day]) // !!!Currently not supported!!!
	 *
	 * Interval:
	 * Defines the interval for the given frequency.
	 *
	 * Days:
	 * A comma seperated list with all the days which will be used
	 * => Mo,Tu,We,Th,Fr,Sa,Su
	 *
	 *  Examples:
	 * - Daily: D+1
	 * - Weekly: W+1
	 * - Monthly at the same day number (e.g. 7.): M+1
	 * - Monthly at the same week day (e.g Monday): M+1+Mo
	 * - Monthly at the same week days (e.g Monday and Friday): M+1+Mo+Fr
	 * - Yearly at the first of January: Y+1
	 * - Working days: W+1+Mo,Tu,We,Th,Fr
	 * - Weekend: W+1+Sa,Su
	 * - Every two weeks monday and tuesday: W+2+Mo,Tu
	 * - Every Public Holiday (Germany as country and Rheinland-Pfalz as district): P(DE,RP)+1
	 * - At School Holidays every monday and wednesday: H(DE,RP)+1+Mo,We
	 */
	periodRule?: Maybe<Scalars["String"]>;
	periodEndless?: Maybe<Scalars["Boolean"]>;
};

export type UpdateAbsence = {
	from: Scalars["Timestamp"];
	to: Scalars["Timestamp"];
	name: Scalars["String"];
	description?: Maybe<Scalars["String"]>;
};

export type UpdateDepartment = {
	name: Scalars["String"];
	description?: Maybe<Scalars["String"]>;
};

export enum UpdateDepartmentAdminTypes {
	Accept = "ACCEPT",
	Decline = "DECLINE",
}

export type UpdateDepartmentBooking = {
	departmentServiceID: Scalars["ID"];
	timeslot: UpdateTemporaryTimeslot;
	customer: UpdatePersonData;
};

export type UpdateDepartmentService = {
	name: Scalars["String"];
	duration: Scalars["Int"];
	additional?: Maybe<Scalars["String"]>;
};

export type UpdatePersonData = {
	firstName: Scalars["String"];
	lastName: Scalars["String"];
	email: Scalars["String"];
	phone: Scalars["String"];
	street: Scalars["String"];
	houseNumber: Scalars["String"];
	plz: Scalars["String"];
	city: Scalars["String"];
};

export type UpdatePublicVation = {
	from: Scalars["Timestamp"];
	to: Scalars["Timestamp"];
	name: Scalars["String"];
};

export type UpdateTemporaryTimeslot = {
	from: Scalars["Timestamp"];
	to: Scalars["Timestamp"];
};

export type UpdateWorkingHour = {
	from: UpdateWorkingHourRange;
	to: UpdateWorkingHourRange;
	day: WorkingHourDay;
};

export type UpdateWorkingHourRange = {
	hour: Scalars["Int"];
	minute: Scalars["Int"];
};

export type User = {
	__typename?: "User";
	id: Scalars["ID"];
	email?: Maybe<Scalars["String"]>;
	firstName?: Maybe<Scalars["String"]>;
	lastName?: Maybe<Scalars["String"]>;
	fullName?: Maybe<Scalars["String"]>;
	imageURL?: Maybe<Scalars["String"]>;
};

export type WorkingHour = {
	__typename?: "WorkingHour";
	id: Scalars["ID"];
	userID: Scalars["String"];
	from: WorkingHourRange;
	to: WorkingHourRange;
	day: WorkingHourDay;
};

export enum WorkingHourDay {
	Mo = "MO",
	Tu = "TU",
	We = "WE",
	Th = "TH",
	Fr = "FR",
	Sa = "SA",
	Su = "SU",
}

export type WorkingHourRange = {
	__typename?: "WorkingHourRange";
	hour: Scalars["Int"];
	minute: Scalars["Int"];
};

export const BookServiceDocument = gql`
	mutation bookService($departmentBooking: UpdateDepartmentBooking!) {
		createDepartmentBooking(departmentBooking: $departmentBooking) {
			id
		}
	}
`;
export type BookServiceMutationFn = Apollo.MutationFunction<BookServiceMutation, BookServiceMutationVariables>;

/**
 * __useBookServiceMutation__
 *
 * To run a mutation, you first call `useBookServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookServiceMutation, { data, loading, error }] = useBookServiceMutation({
 *   variables: {
 *      departmentBooking: // value for 'departmentBooking'
 *   },
 * });
 */
export function useBookServiceMutation(
	baseOptions?: Apollo.MutationHookOptions<BookServiceMutation, BookServiceMutationVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<BookServiceMutation, BookServiceMutationVariables>(BookServiceDocument, options);
}
export type BookServiceMutationHookResult = ReturnType<typeof useBookServiceMutation>;
export type BookServiceMutationResult = Apollo.MutationResult<BookServiceMutation>;
export type BookServiceMutationOptions = Apollo.BaseMutationOptions<BookServiceMutation, BookServiceMutationVariables>;
export const StornoServiceDocument = gql`
	mutation stornoService($identifier: String!) {
		cancelDepartmentBooking(identifier: $identifier) {
			id
		}
	}
`;
export type StornoServiceMutationFn = Apollo.MutationFunction<StornoServiceMutation, StornoServiceMutationVariables>;

/**
 * __useStornoServiceMutation__
 *
 * To run a mutation, you first call `useStornoServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStornoServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stornoServiceMutation, { data, loading, error }] = useStornoServiceMutation({
 *   variables: {
 *      identifier: // value for 'identifier'
 *   },
 * });
 */
export function useStornoServiceMutation(
	baseOptions?: Apollo.MutationHookOptions<StornoServiceMutation, StornoServiceMutationVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<StornoServiceMutation, StornoServiceMutationVariables>(StornoServiceDocument, options);
}
export type StornoServiceMutationHookResult = ReturnType<typeof useStornoServiceMutation>;
export type StornoServiceMutationResult = Apollo.MutationResult<StornoServiceMutation>;
export type StornoServiceMutationOptions = Apollo.BaseMutationOptions<
	StornoServiceMutation,
	StornoServiceMutationVariables
>;
export const DepartmentsDocument = gql`
	query departments {
		departments {
			id
			name
			description
			services {
				id
				name
				duration
				additional
			}
		}
	}
`;

/**
 * __useDepartmentsQuery__
 *
 * To run a query within a React component, call `useDepartmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepartmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepartmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDepartmentsQuery(
	baseOptions?: Apollo.QueryHookOptions<DepartmentsQuery, DepartmentsQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<DepartmentsQuery, DepartmentsQueryVariables>(DepartmentsDocument, options);
}
export function useDepartmentsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<DepartmentsQuery, DepartmentsQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<DepartmentsQuery, DepartmentsQueryVariables>(DepartmentsDocument, options);
}
export type DepartmentsQueryHookResult = ReturnType<typeof useDepartmentsQuery>;
export type DepartmentsLazyQueryHookResult = ReturnType<typeof useDepartmentsLazyQuery>;
export type DepartmentsQueryResult = Apollo.QueryResult<DepartmentsQuery, DepartmentsQueryVariables>;
export const DepartmentServiceTimeslotsDocument = gql`
	query departmentServiceTimeslots($serviceID: ID!) {
		departmentServiceTimeslots(serviceID: $serviceID) {
			from
			to
		}
	}
`;

/**
 * __useDepartmentServiceTimeslotsQuery__
 *
 * To run a query within a React component, call `useDepartmentServiceTimeslotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepartmentServiceTimeslotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepartmentServiceTimeslotsQuery({
 *   variables: {
 *      serviceID: // value for 'serviceID'
 *   },
 * });
 */
export function useDepartmentServiceTimeslotsQuery(
	baseOptions: Apollo.QueryHookOptions<DepartmentServiceTimeslotsQuery, DepartmentServiceTimeslotsQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<DepartmentServiceTimeslotsQuery, DepartmentServiceTimeslotsQueryVariables>(
		DepartmentServiceTimeslotsDocument,
		options
	);
}
export function useDepartmentServiceTimeslotsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<DepartmentServiceTimeslotsQuery, DepartmentServiceTimeslotsQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<DepartmentServiceTimeslotsQuery, DepartmentServiceTimeslotsQueryVariables>(
		DepartmentServiceTimeslotsDocument,
		options
	);
}
export type DepartmentServiceTimeslotsQueryHookResult = ReturnType<typeof useDepartmentServiceTimeslotsQuery>;
export type DepartmentServiceTimeslotsLazyQueryHookResult = ReturnType<typeof useDepartmentServiceTimeslotsLazyQuery>;
export type DepartmentServiceTimeslotsQueryResult = Apollo.QueryResult<
	DepartmentServiceTimeslotsQuery,
	DepartmentServiceTimeslotsQueryVariables
>;
export type BookServiceMutationVariables = Exact<{
	departmentBooking: UpdateDepartmentBooking;
}>;

export type BookServiceMutation = { __typename?: "Mutation" } & {
	createDepartmentBooking: { __typename?: "DepartmentBooking" } & Pick<DepartmentBooking, "id">;
};

export type StornoServiceMutationVariables = Exact<{
	identifier: Scalars["String"];
}>;

export type StornoServiceMutation = { __typename?: "Mutation" } & {
	cancelDepartmentBooking: { __typename?: "DepartmentBooking" } & Pick<DepartmentBooking, "id">;
};

export type DepartmentsQueryVariables = Exact<{ [key: string]: never }>;

export type DepartmentsQuery = { __typename?: "Query" } & {
	departments: Array<
		{ __typename?: "Department" } & Pick<Department, "id" | "name" | "description"> & {
				services: Array<
					{ __typename?: "DepartmentService" } & Pick<
						DepartmentService,
						"id" | "name" | "duration" | "additional"
					>
				>;
			}
	>;
};

export type DepartmentServiceTimeslotsQueryVariables = Exact<{
	serviceID: Scalars["ID"];
}>;

export type DepartmentServiceTimeslotsQuery = { __typename?: "Query" } & {
	departmentServiceTimeslots: Array<{ __typename?: "TemporaryTimeslot" } & Pick<TemporaryTimeslot, "from" | "to">>;
};

export const namedOperations = {
	Query: {
		departments: "departments",
		departmentServiceTimeslots: "departmentServiceTimeslots",
	},
	Mutation: {
		bookService: "bookService",
		stornoService: "stornoService",
	},
};
