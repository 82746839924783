import React, { Component } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import de from "date-fns/locale/de";
import { addDays } from "date-fns/esm";
import Utils from "../../Helper/Utils";
import { addMonths } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { t } from "../../Helper/TranslationHandler";
import { Dialog, Button } from "@onedash/tools";
registerLocale("de", de);
let startDate = addDays(new Date(), 5);

export interface PublicFacilityDatePickerProps {
	onDateRangeSelected: (dateString: string) => void;
	disabledDays?: any[];
}

export interface PublicFacilityDatePickerState {}

class PublicFacilityDatePicker extends Component<PublicFacilityDatePickerProps, PublicFacilityDatePickerState> {
	datePickerDialog = React.createRef<Dialog>();
	state = {
		showCalendar: false,
		selectedStartDate: startDate,
		selectedEndDate: startDate,
		startDate: startDate,
		lastSelectedDate: startDate,
	};
	changeSelectedDate = (date: Date) => {
		let newDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
		this.setState({
			lastSelectedDate: this.state.selectedStartDate,
			selectedStartDate: newDate,
		});
	};

	checkDayPickerClass = (date: Date) => {
		let disabled = false;
		let newDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
		if (this.props.disabledDays) {
			const booking = this.props.disabledDays.find(
				(booking: any) =>
					newDate.getTime() >= Number(booking.timestamp_from) &&
					newDate.getTime() <= Number(booking.timestamp_to)
			);
			if (booking) {
				disabled = true;
			}
		}
		if (disabled) {
			return "day-is-disabled";
		}

		if (this.state.selectedStartDate.getTime() !== startDate.getTime()) {
			let newDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
			if (newDate.getTime() === this.state.lastSelectedDate.getTime()) {
				return "selected-start-date";
			}
		}
		return "";
	};

	getDateRange = () => {
		if (this.state.lastSelectedDate.getTime() !== startDate.getTime()) {
			let startDate = this.state.selectedStartDate.getTime();
			let endDate = this.state.lastSelectedDate.getTime();
			if (startDate === endDate) {
				return Utils.formatDate(this.state.selectedStartDate);
			} else if (startDate < endDate) {
				return (
					Utils.formatDate(this.state.selectedStartDate) +
					" - " +
					Utils.formatDate(this.state.lastSelectedDate)
				);
			} else {
				return (
					Utils.formatDate(this.state.lastSelectedDate) +
					" - " +
					Utils.formatDate(this.state.selectedStartDate)
				);
			}
		} else {
			return Utils.formatDate(this.state.selectedStartDate);
		}
	};

	onFinish = () => {
		if (this.datePickerDialog.current) {
			this.datePickerDialog.current.hide();
		}
		let date: any = "";
		if (this.state.selectedStartDate.getTime() !== startDate.getTime()) {
			let startDate = this.state.selectedStartDate.getTime();
			let endDate = this.state.lastSelectedDate.getTime();
			if (startDate === endDate || endDate === this.state.startDate.getTime()) {
				date = this.state.selectedStartDate.getTime();
			} else if (startDate < endDate) {
				date = this.state.selectedStartDate.getTime() + "-" + this.state.lastSelectedDate.getTime();
			} else {
				date = this.state.lastSelectedDate.getTime() + "-" + this.state.selectedStartDate.getTime();
			}
		}
		this.props.onDateRangeSelected(date);
	};

	public open = () => {
		if (this.datePickerDialog.current) {
			this.datePickerDialog.current.show();
		}
	};

	render() {
		return (
			<Dialog buttons={[]} className="no-padding" isOpen={false} ref={this.datePickerDialog}>
				<DatePicker
					locale="de"
					selected={this.state.selectedStartDate}
					minDate={this.state.startDate}
					maxDate={addMonths(new Date(), 13)}
					onChange={this.changeSelectedDate}
					dayClassName={this.checkDayPickerClass}
					inline
				/>

				<div className="confirm-date-wrapper">
					{this.state.selectedStartDate.getTime() !== this.state.startDate.getTime() && (
						<Button mode="primary" className="confirm-date" onClick={this.onFinish}>
							<span className="bold">{t("publicFacilities.dateEntry.confirmBtn")}</span>
							<br />
							<span className="date-range">{this.getDateRange()}</span>
						</Button>
					)}
				</div>
			</Dialog>
		);
	}
}

export default PublicFacilityDatePicker;
