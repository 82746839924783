import * as React from "react";

export interface ImpressProps {
	inline?: boolean;
}

const Impress: React.SFC<ImpressProps> = ({ inline }) => {
	return (
		<div className={inline === true ? "impress about-text" : "impress about-text standalone"}>
			{!inline && <h2>Impressum</h2>}
			<h3>Verbandsgemeinde Wittlich-Land</h3>
			<h4>Verantwortliche Person</h4>
			<p>
				Bürgermeister Manuel Follmann
				<br />
				Kurfürstenstraße 1<br />
				54516 Wittlich
				<br />
			</p>
			<h4>Kontakt</h4>
			<p>
				Telefon: +49 6571 / 107 - 0<br />
				Telefax: +49 6571 / 107 - 155
				<br />
			</p>
			<p>
				E-Mail: <a href="mailto:info@vg-wittlich-land.de">info@vg-wittlich-land.de</a> <br />
				Internet: <a href="www.vg-wittlich-land.de">www.vg-wittlich-land.de</a>
			</p>
			<p>
				Die Verbandsgemeinde Wittlich-Land ist eine Körperschaft des öffentlichen Rechts und wird durch den
				Bürgermeister vertreten. Die zuständige Aufsichtsbehörde ist die{" "}
				<a href="https://www.bernkastel-wittlich.de/startseite.html">Kreisverwaltung Bernkastel-Wittlich</a>.
			</p>
			<p>Umsatzsteuer-ID: DE149946236</p>

			<h4>Internet - Redaktion</h4>
			<p>FB 1 - Zentrale Dienste / Presse- und Öffentlichkeitsarbeit</p>
			<p>Carina Alt-Linden</p>
			<p>
				Kurfürstenststraße 1<br />
				54516 Wittlich
			</p>
			<p>
				Telefon: +49 6571 / 107 - 132
				<br />
				E-Mail: <a href="mailto:carina.alt-linden@vg-wittlich-land.de">carina.alt-linden@vg-wittlich-land.de</a>
			</p>

			<h4>Verantwortlichkeit im Sinne des § 55 Abs. 2 Rundfunkstaatsvertrags</h4>
			<p>FB 1 - Zentrale Dienste / Leitung der Sachgebietsgruppe -</p>
			<p>Dennis Kinne </p>
			<p>
				Kurfürstenstraße 1 <br />
				54516 Wittlich
			</p>
			<p>
				Telefon: +49 6571 / 107 - 136
				<br />
				E-Mail: <a href="mailto:dennis.kinne@vg-wittlich-land.de">dennis.kinne@vg-wittlich-land.de</a>
			</p>

			<h3>Rechtshinweise</h3>
			<h4>Inhalte</h4>
			<p>
				Die Verbandsgemeinde Wittlich-Land prüft und aktualisiert die Informationen auf dieser Website ständig.
				Trotz aller Sorgfalt können sich die Daten inzwischen verändert haben. Eine Haftung oder Garantie für
				die Aktualität, Richtigkeit, Vollständigkeit, Qualität und jederzeitigen Verfügbarkeit der zur Verfügung
				gestellten Informationen kann daher nicht übernommen werden. Für Schäden, die durch die Nutzung der zur
				Verfügung gestellten Informationen oder Daten oder durch die Nutzung fehlerhafter und/oder
				unvollständiger Informationen oder Daten verursacht werden, haftet die Verbandsgemeinde Wittlich-Land
				nicht, sofern ihr nicht vorsätzliches oder grob fahrlässiges Verschulden zur Last fällt. Dieser
				Ausschluss gilt nicht für Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit. Die
				Verbandsgemeinde Wittlich-Land behält sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot
				ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise
				oder endgültig einzustellen.
			</p>
			<h4>Fremde Inhalte</h4>
			<p>
				Diese Webseite enthält als Information auch entsprechend gekennzeichnete Links oder Verweise auf
				Webseiten Dritter. Die Verantwortlichkeit für diese fremden Inhalte liegt alleine bei dem Anbieter, der
				die Inhalte bereithält.
			</p>
			<p>
				Für fremde Inhalte – insbesondere rechtswidrige und somit strafbare Inhalte - ist sie nur dann
				verantwortlich, wenn sie von ihnen Kenntnis erhält und es technisch möglich und zumutbar ist, deren
				Nutzung zu verhindern. Hierunter fallen auch Verweise auf Inhalte anderer Anbieter. Die verlinkten
				Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte
				waren zum Zeitpunkt der Verlinkung nicht erkennbar. Die Verweise werden zwar regelmäßig auf eventuelle
				rechtswidrige Inhalte geprüft, es kann jedoch keine Gewähr gegeben werden, dass es sich nicht um
				rechtswidrige Inhalte handelt. Die Verbandsgemeinde Wittlich-Land hat keinen Einfluss auf die Gestaltung
				dieser fremden Inhalte. Diese können deshalb geändert worden sein, ohne dass die Verbandsgemeinde
				Wittlich-Land hiervon Kenntnis hat. Verweise auf fremde Inhalte sind durch einen Link kenntlich gemacht.
			</p>
			<h4>Urheberschutz</h4>
			<p>
				Inhalt und Struktur der Verbandsgemeinde Wittlich-Land sind urheberrechtlich geschützt. Die
				Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des
				Urheberrechtes von Informationen oder Daten, insbesondere die Verwendung von Texten, Textteilen oder
				Bildmaterial bedarf der vorherigen Zustimmung der Verbandsgemeindeverwaltung Wittlich-Land.
			</p>
			<h4>Hyperlinks</h4>
			<p>
				Hyperlinks auf die Startseite der Verbandsgemeinde Wittlich-Land sind ohne vorherige Zustimmung
				gestattet.
			</p>
			<h4>Attribution</h4>
			<p>
				Einige Icons wurden durch Freepik von <a href="www.flaticon.com">www.flaticon.com</a> erstellt.
			</p>

			<h3>Weiteres</h3>
			<h4>Gestaltung</h4>

			<p>
				Diese App wurde von <a href="https://onedash.de">OneDash.</a> erstellt.
			</p>
		</div>
	);
};

export default Impress;
