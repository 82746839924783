const THEME = {
	dark: {},
	light: {
		black: "#313638",
		white: "white",
		"black-rgb": "0, 0, 0",
		"white-rgb": "255, 255, 255",
		text: "#333333",

		// Defined colors
		"background-color": "radial-gradient(circle, #ffffff, #fdfeff, #fbfdff, #f7fdff, #f3fdff)",

		orange: "#e29051",
		"transparent-orange": "#e290519a",
		"light-orange": "#fcf4ed",
		red: "#ef6461",
		blue: "#3469d3",
		"dark-blue": "#011e36",
		grey: "#696969",
		"light-grey": "rgba(var(--black-rgb), 0.07)",
		"spinner-color": "#313638",
		paypal: "#003087",
		"honeybadger-bg": "#171c28",

		"feat-1": "#0866b0",
		"feat-2": "#064678",
		"feat-3": "#011e36",
	},

	all: {
		required: "#e56038",
		invalid: "#e56038",
		success: "#7fcc76",
		disabledBtn: "#ebebeb",
		white: "white",
		dialogBackground: "white",
		dialogTextColor: "#393939",
		dialogFooterBorder: "#ebebeb",
		dialogFooterColor: "#777777",
		dialogShadow: "1px 1px 3px black",
		"spinner-color": "#064678",
		"font-size": "1rem",
		"input-width": "350px",
		"tools-border-radius": "10px",
		"button-border-radius": "25px",
		"dialog-bg": "rgba(0, 0, 0, 0.85)",

		"scrollbar-bg": "#f9fafc",
		"scrollbar-thumb": "#c7cfd3",
		"scrollbar-width": "11px",
	},
};
export default THEME;
