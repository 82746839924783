import * as React from "react";

export interface VideoAGBProps {
	inline?: boolean;
}

const VideoAGB: React.SFC<VideoAGBProps> = ({ inline }) => {
	return (
		<div className={inline === true ? "video-agb about-text" : "video-agb about-text standalone"}>
			{!inline && <h2> Nutzungs­&shy;bedingungen der Video&shy;plattform</h2>}
			<h3>Für Beitragsersteller</h3>
			<p>
				Das Anbieten von Videomaterial zur Veröffentlichung auf www.vg-wittlich-land.de erfolgt nach den
				aktuellen rechtlichen Rahmenbedingungen. Vor der Veröffentlichung eines Videobeitrages werden diese
				durch uns gesichtet um Verstöße gegen das Urheberrecht und den Datenschutz zu vermeiden. Weiterhin ist
				es Ihnen nicht gestattet die Videos ausschließlich zu Werbezwecke zu veröffentlichen.
			</p>

			<h4>Urheberrecht</h4>
			<p>
				Bieten Sie nur Videos an, welche Sie selbst produziert haben oder wenn Sie über alle erforderlichen
				Rechte an diesem halten. Es soll keine Inhalte Dritter enthalten. Dies sind insbesondere Musik,
				Ausschnitte von urheberrechtlich geschützten Programmen oder Videos die andere produziert haben.
			</p>
			<h4>Datenschutz</h4>
			<p>
				Der Schutz von personenbezogenen Daten unterliegt strengen Richtlinien. Diese sind bei der
				Veröffentlichung von Videos zwingend zu beachten. Das veröffentlichen von personenbezogenen Daten ohne
				vorherige Zustimmung der Person führt zur Nichtveröffentlichung bzw. Löschung des Videos.
			</p>

			<h4>Werbung</h4>
			<p>
				Wir behalten uns die Veröffentlichung von Videos, die zum Zweck der Werbung eines Produktes, Lokalität
				oder Marke erstellt wurden, vor.
			</p>
			<h3>Für Betroffene</h3>
			<h4>Inhalte</h4>
			<p>
				Sollten Sie sich durch ein veröffentlichtes Video in Ihren Rechten verletzt sehen, so wenden Sie sich
				bitte an die E-Mailadresse <a href="mailto:video@vg-wittlich-land.de">video@vg-wittlich-land.de</a>. Wir
				werden uns um Ihr Anliegen kümmern und bei Bedarf das Video löschen.
			</p>
		</div>
	);
};

export default VideoAGB;
