import React from "react";
import ReactDOM from "react-dom";
import App from "./Pages/App/App";
import "./Style/default.scss";
import { Router } from "react-router-dom";
import RouterHistory from "./Helper/RouterHistory";
import "./Helper/TranslationHandler";
import * as ServiceWorker from "./ServiceWorkerMain";
import Hyphenated from "react-hyphen";
import de from "hyphenated-de";
import { StyleLoader } from "@onedash/tools";
import THEME from "./Pages/App/theme";
import smoothscroll from "smoothscroll-polyfill";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import ENV from "./Helper/ENV";
smoothscroll.polyfill();

const client = new ApolloClient({
	uri: ENV.default.gqlURL,
	cache: new InMemoryCache(),
});

ReactDOM.render(
	<StyleLoader theme="light" toolStyle="vg-twenty" additionalStyle={THEME}>
		<Router history={RouterHistory}>
			<ApolloProvider client={client}>
				<Hyphenated language={de}>
					<App />
				</Hyphenated>
			</ApolloProvider>
		</Router>
	</StyleLoader>,
	document.getElementById("root")
);

ServiceWorker.register();

let hidden: any, visibilityChange: any;
if (typeof document.hidden !== "undefined") {
	// Opera 12.10 and Firefox 18 and later support
	hidden = "hidden";
	visibilityChange = "visibilitychange";
} else if (typeof (document as any).msHidden !== "undefined") {
	hidden = "msHidden";
	visibilityChange = "msvisibilitychange";
} else if (typeof (document as any).webkitHidden !== "undefined") {
	hidden = "webkitHidden";
	visibilityChange = "webkitvisibilitychange";
}

function handleVisibilityChange() {
	if (!(document as any)[hidden]) {
		ServiceWorker.register({ immediate: true } as any);
	}
}
//Registeres the
document.addEventListener(visibilityChange, handleVisibilityChange, false);
