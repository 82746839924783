import React, { Component } from "react";
import "./Header.scss";
import Icon from "../../Resources/Icons/Icon";
import RouterHistory from "../../Helper/RouterHistory";
import VGIcon from "../../Resources/Images/vg-logo.png";
import VGText from "../../Resources/Images/vg-text.png";
import MediaRender from "../../Pages/App/MediaRender";
export interface HeaderProps {
	title: string;
	navBack?: boolean;
	mode: "expanded" | "collapsed" | "custom";
}

class Header extends Component<HeaderProps> {
	state = {
		title: "",
	};

	buildClasses = () => {
		const classList = `header ${this.props.mode}`;
		return classList;
	};

	back = () => {
		RouterHistory.push(window.reduxStore.getState().backPath);
	};

	componentDidMount() {
		window.reduxStore.subscribe(() => {
			const state = window.reduxStore.getState();
			this.setState({
				headerSwimmingPool: state.headerSwimmingPool,
			});
		});
	}

	render() {
		return (
			<div className={this.buildClasses()}>
				{this.props.mode === "custom" && (
					<>
						<MediaRender type="mobile">
							<div onClick={this.back} className="back-btn">
								<Icon name="back" color="white" size={25} />
							</div>
						</MediaRender>
						<div className="header-bg-overlay"></div>
					</>
				)}

				<div className="wrapper">
					{this.props.mode === "collapsed" && (
						<div onClick={this.back} className="back-btn">
							<Icon name="back" color="white" size={25} />
						</div>
					)}
					<MediaRender type="mobile">
						{this.props.mode !== "collapsed" && this.props.mode !== "custom" && (
							<a
								href="https://www.vg-wittlich-land.de"
								rel="noopener noreferrer"
								target="_blank"
								className="vg-icon">
								<img alt="Wappen der Verbandsgemeinde Wittlich - Land" src={VGIcon} />
							</a>
						)}
					</MediaRender>

					{this.props.mode !== "custom" && (
						<>
							<MediaRender type="desktop">
								<a
									href="https://www.vg-wittlich-land.de"
									rel="noopener noreferrer"
									target="_blank"
									className="vg-text">
									<img alt="Slogan der Verbandsgemeinde Wittlich - Land" src={VGText} />
								</a>
							</MediaRender>
							<h1 className="title" dangerouslySetInnerHTML={{ __html: this.props.title }}></h1>
						</>
					)}
				</div>
			</div>
		);
	}
}

export default Header;
