import React, { Component } from "react";
import "./Home.scss";
import Icon from "../../Resources/Icons/Icon";
import { Link } from "react-router-dom";
import { t } from "../../Helper/TranslationHandler";

class Home extends Component {
	render() {
		return (
			<div className="home">
				<h2 className="home-title">{t("home.title")}</h2>
				<div className="home-selection-items">
					<HomeSelectionItem
						iconName="calendar"
						title={t("home.reservierung.title")}
						text={t("home.reservierung.text")}
						link="/terminvereinbarung/department-selection"
					/>
					<HomeSelectionItem
						iconName="shopping-card"
						title={t("home.giftCards.title")}
						text={t("home.giftCards.text")}
						link="/online-gutscheine"
					/>
					<HomeSelectionItem
						iconName="search"
						title={t("home.fundbuero.title")}
						text={t("home.fundbuero.text")}
						link="https://antrag-kommunal.service.rlp.de/civ.public/start.html?oe=00.00.WITTVG&mode=cc&cc_key=OZG_Fundsachen"
						external
					/>
					<HomeSelectionItem
						iconName="building"
						title={t("home.publicFacilities.title")}
						text={t("home.publicFacilities.text")}
						link="/gebaeude-reservieren/type-selection"
					/>
				</div>
			</div>
		);
	}
}

interface HomeSelectionItemProps {
	iconName: string;
	title: string;
	text: string;
	link: string;
	external?: boolean;
}

const HomeSelectionItem: React.FunctionComponent<HomeSelectionItemProps> = ({
	iconName,
	title,
	text,
	link,
	external,
}) => {
	const content = (
		<>
			<div className="selection-icon">
				<Icon size={32} color="white" name={iconName} />
			</div>
			<div className="selection-text">
				<h3>{title}</h3>
				<p>{text}</p>
			</div>
		</>
	);
	return (
		<div className="home-selection-item">
			{external ? <a href={link}>{content}</a> : <Link to={link}>{content}</Link>}
		</div>
	);
};

export default Home;
