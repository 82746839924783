import React from "react";
import Icons from "./icons.svg"; // Path to your icons.svg
import "./Icon.scss";

import Logo from "./Logo.svg";

type IconProps = {
	name: string;
	color?: string;
	size?: number;
	className?: string;
	deg?: number;
};

const Icon = ({ name, color, size, className, deg }: IconProps) => {
	if (name === "logo") {
		return <img alt="Verbandsgemeinde Wittlich-Land" src={Logo} width={size} height={size} />;
	}
	return (
		<svg
			className={`icon icon-${name} ${className}`}
			style={{ transform: `rotateZ(${deg}deg)` }}
			fill={color}
			width={size}
			height={size}>
			<use xlinkHref={`${Icons}#icon-${name}`} />
		</svg>
	);
};

export default Icon;
