import React, { Component } from "react";
import "./Navigation.scss";
import Icon from "../../Resources/Icons/Icon";
import RouterHistory from "../../Helper/RouterHistory";
import Cookies from "js-cookie";
import { t } from "../../Helper/TranslationHandler";
import MediaRender from "../../Pages/App/MediaRender";
import Utils from "../../Helper/Utils";

export interface NavigationProps {
	changeDisplayMode: (mode: headerModes, title: string) => void;
	mainContentIsChanging: Function;
}
type mainRoute = {
	title: string;
	description: string;
	iconName: string;
	mode: "expanded" | "collapsed" | "custom";
	path: string;
};
type route = {
	title: string;
	mode: "expanded" | "collapsed" | "custom";
	path: string;
};
let mainRoutes: mainRoute[] = [
	{
		description: t("navigation.buttons.home.description"),
		title: t("navigation.buttons.home.title"),
		iconName: "home",
		mode: "expanded",
		path: "/",
	},
	{
		description: t("navigation.buttons.news.description"),
		iconName: "newspaper",
		mode: "collapsed",
		path: "/aktuelles",
		title: t("navigation.buttons.news.title"),
	},
	{
		description: t("navigation.buttons.video.description"),
		title: t("navigation.buttons.video.title"),
		iconName: "video",
		mode: "collapsed",
		path: "/videos",
	},
	{
		description: t("navigation.buttons.info.description"),
		title: t("navigation.buttons.info.title"),
		iconName: "info",
		mode: "expanded",
		path: "/about",
	},
];

let subRoutes: route[] = [
	{
		mode: "collapsed",
		path: "/terminvereinbarung",
		title: t("navigation.routes.termin"),
	},
	{
		mode: "collapsed",
		path: "/gebaeude-reservieren",
		title: t("navigation.routes.publicFacilities"),
	},
	{
		mode: "collapsed",
		path: "/online-fundbuero",
		title: t("navigation.routes.fundbuero"),
	},
	{
		mode: "collapsed",
		path: "/videos",
		title: t("navigation.routes.videos"),
	},
	{
		mode: "collapsed",
		path: "/online-gutscheine",
		title: t("navigation.routes.giftCard"),
	},
	{
		mode: "custom",
		path: "/corona-swimming",
		title: t("navigation.routes.coronaSwimming"),
	},
];

let fontSizes = {
	small: "12px",
	normal: "14px",
	large: "17px",
	extraLarge: "20px",
};
class Navigation extends Component<NavigationProps> {
	textSizeTimeout: any;
	state = {
		textZoomVisible: false,
		textSize: "normal",
	};
	rmActiveClass = () => {
		let el = document.querySelector(".main-navigation .active");
		if (el) el.classList.remove("active");
	};
	adjustPage = (e: any, to: string, mode: headerModes, description: string) => {
		setTimeout(() => {
			RouterHistory.push(to);
		}, 500);
	};
	componentDidMount() {
		this.navigate(RouterHistory.location);
		RouterHistory.listen(this.navigate);
		this.changeTextSize();
		window.reduxStore.subscribe(() => {
			if (window.reduxStore.getState().textSize === true) {
				this.setState({
					textZoomVisible: true,
				});
				this.textSizeTimeout = setTimeout(this.hideTextSizePopover, 2500);
			}
		});
	}

	navigate = (location: any) => {
		let el: route | mainRoute | undefined = mainRoutes.find((x) => x.path === location.pathname) as any;
		if (!el) el = subRoutes.find((x) => location.pathname.indexOf(x.path) > -1);
		if (el) {
			this.props.changeDisplayMode(el.mode, el.title);
			this.props.mainContentIsChanging();
		}
	};
	setBodyFontSize = (size: string) => {
		let sizeNum = (fontSizes as any)[size];
		if (Utils.cookieAllowed() === true) {
			Cookies.set("fontSize", size);
		}
		this.setState({ textSize: size });
		let html = document.querySelector("html");
		if (html) html.style.fontSize = sizeNum;
		document.body.style.fontSize = sizeNum;
	};
	changeTextSize = (mode?: "+" | "-") => {
		//Page got rendered => Set last size
		clearTimeout(this.textSizeTimeout);

		if (!mode) {
			let size = Cookies.get("fontSize");
			if (size) {
				this.setBodyFontSize(size);
			}
		} else {
			this.textSizeTimeout = setTimeout(this.hideTextSizePopover, 2500);
			let keys = Object.keys(fontSizes);
			if (mode === "+") {
				if (keys.indexOf(this.state.textSize) < keys.length - 1) {
					let newSize = keys[keys.indexOf(this.state.textSize) + 1];
					this.setState({
						fontSize: newSize,
					});
					this.setBodyFontSize(newSize);
				}
			} else {
				if (keys.indexOf(this.state.textSize) > 0) {
					let newSize = keys[keys.indexOf(this.state.textSize) - 1];
					this.setState({
						fontSize: newSize,
					});
					this.setBodyFontSize(newSize);
				}
			}
		}
	};
	hideTextSizePopover = () => {
		this.setState({ textZoomVisible: false });
	};

	render() {
		return (
			<div className="main-navigation-wrapper">
				<MediaRender type="mobile">
					<div className={this.state.textZoomVisible ? "text-zoom active" : "text-zoom"}>
						<button onClick={() => this.changeTextSize("-")}>-</button>
						<div className="font-size-indicator">
							<p>{t("navigation.textSize.small")}</p>
							<span className={this.state.textSize === "small" ? "active" : ""} />
							<span className={this.state.textSize === "normal" ? "active" : ""} />
							<span className={this.state.textSize === "large" ? "active" : ""} />
							<span className={this.state.textSize === "extraLarge" ? "active" : ""} />
							<p>{t("navigation.textSize.big")}</p>
						</div>
						<button onClick={() => this.changeTextSize("+")}>+</button>
					</div>
				</MediaRender>
				<nav className="main-navigation">
					<ul>
						<NavItem
							title={mainRoutes[0].title}
							description={mainRoutes[0].description}
							iconName={mainRoutes[0].iconName}
							mode={mainRoutes[0].mode}
							path={mainRoutes[0].path}
							size={25}
						/>
						<MediaRender size={1200} type="mobile">
							<NavItem
								title={mainRoutes[1].title}
								description={mainRoutes[1].description}
								iconName={mainRoutes[1].iconName}
								mode={mainRoutes[1].mode}
								path={mainRoutes[1].path}
								size={30}
							/>
						</MediaRender>

						<NavItem
							title={mainRoutes[2].title}
							description={mainRoutes[2].description}
							iconName={mainRoutes[2].iconName}
							mode={mainRoutes[2].mode}
							path={mainRoutes[2].path}
							size={30}
						/>
						<NavItem
							title={mainRoutes[3].title}
							description={mainRoutes[3].description}
							iconName={mainRoutes[3].iconName}
							mode={mainRoutes[3].mode}
							path={mainRoutes[3].path}
							size={25}
						/>
					</ul>
				</nav>
			</div>
		);
	}
}

export interface NavItemProps {
	iconName: string;
	description: string;
	path: string;
	mode: headerModes;
	title: string;
	size: number;
}

class NavItem extends Component<NavItemProps> {
	btn = React.createRef<HTMLAnchorElement>();
	checkIfIsActive = (location: any) => {
		let path = location.pathname;
		if (this.btn.current) {
			if (path === this.props.path || (path.indexOf(this.props.path) !== -1 && this.props.path !== "/")) {
				this.btn.current.classList.add("active");
			} else {
				this.btn.current.classList.remove("active");
			}
		}
	};
	componentDidMount() {
		RouterHistory.listen(this.checkIfIsActive);
		let path = RouterHistory.location.pathname;
		if (this.btn.current) {
			if (path === this.props.path || (path.indexOf(this.props.path) !== -1 && this.props.path !== "/")) {
				this.btn.current.click();
				this.btn.current.classList.add("active");
			}
		}
	}
	navTo = (e: any) => {
		if (RouterHistory.location.pathname !== this.props.path) {
			RouterHistory.push(this.props.path);
		}
		e.preventDefault();
	};
	render() {
		return (
			<li>
				<a className="nav-btn" href={this.props.path} ref={this.btn} onClick={this.navTo}>
					<Icon color="black" className="nav-icon" size={this.props.size} name={this.props.iconName} />
					<p className="nav-description">{this.props.description}</p>
				</a>
			</li>
		);
	}
}

export default Navigation;
