import React from "react";
import Utils from "../../Helper/Utils";
import "./DepartmentSelection.scss";
import RouterHistory from "../../Helper/RouterHistory";
import moment from "moment";
import { t } from "../../Helper/TranslationHandler";
import { Dialog, Form, Input, Spinner, Button, Boolean } from "@onedash/tools";
import PrivacyText from "../../Components/Utils/PrivacyText";
import { useParams } from "react-router";
import { useBookServiceMutation, useDepartmentsQuery } from "../../Resources/Generated/gql-types";
import ENV from "../../Helper/ENV";
import ReactMarkdown from "react-markdown";
export interface DepartmentDataEntryProps {}

const DepartmentDataEntry = () => {
	const serverResponseDialog = React.useRef<Dialog>(null);
	const { categoryId, date } = useParams<{ categoryId: string; date: string }>();
	const [dateFrom, dateTo] = date.split("*");
	const [state, update] = React.useState({
		disabled: false,
		checkOut: false,
		personalData: {} as any,
		serverResponse: {} as any,
	});

	const { data: departmentData, loading: loadDepartmentData } = useDepartmentsQuery({
		context: {
			headers: {
				"app-id": ENV.default.appId,
			},
		},
		onError: () => {
			RouterHistory.push("/terminvereinbarung/department-selection");
		},
	});
	const [bookService] = useBookServiceMutation({
		context: {
			headers: {
				"app-id": ENV.default.appId,
			},
		},
		onCompleted: (err) => {
			update((s) => ({
				...s,
				serverResponse: {
					type: "S",
					message: "Vielen Dank für Ihre Buchung. Unsere Mitarbeiter melden sich sobald wie mögich.",
				},
			}));
		},
		onError: (err) => {
			update((s) => ({ ...s, serverResponse: { type: "E", message: err.message } }));
		},
	});
	const department = departmentData?.departments.find((x) => x.services.find((y) => y.id === categoryId));
	const service = department?.services.find((y) => y.id === categoryId);

	React.useEffect(() => {
		if (!department && !loadDepartmentData) {
			RouterHistory.push("/terminvereinbarung/department-selection");
		}
	}, [loadDepartmentData, department]);

	React.useEffect(() => {
		Utils.setBackPath("/terminvereinbarung/time-slot-selection/" + categoryId);
	}, [categoryId]);

	const checkValidation = (values: any, form: Form) => {
		if (state.disabled) {
			if (form.validateInputs()) {
				update((s) => ({ ...s, disabled: false }));
			}
		}
	};

	const validateInput = (values: any, form: Form) => {
		if (form.validateInputs()) {
			update((s) => ({ ...s, checkOut: true, personalData: values }));
		} else {
			update((s) => ({
				...s,
				disabled: true,
			}));
		}
	};

	const book = (values: any, form: Form) => {
		if (form.validateInputs()) {
			if (serverResponseDialog.current) {
				serverResponseDialog.current.show();
			}
			bookService({
				variables: {
					departmentBooking: {
						departmentServiceID: categoryId,
						customer: {
							city: state.personalData.city,
							email: state.personalData.email,
							firstName: state.personalData.firstName,
							lastName: state.personalData.lastName,
							houseNumber: "",
							phone: state.personalData.phone,
							plz: state.personalData.plz,
							street: state.personalData.street,
						},
						timeslot: {
							from: dateFrom,
							to: dateTo,
						},
					},
				},
			});
		}
	};

	const redirectToHome = () => {
		RouterHistory.push("/");
	};

	let personalData = state.personalData;
	return (
		<div className="department-booking-data-entry">
			{!state.checkOut && (
				<>
					<h2>{t("dataEntry.title")}</h2>
					<Form onChange={checkValidation} onSubmit={validateInput}>
						<Input
							placeholder={t("dataEntry.firstName.placeholder")}
							name="firstName"
							label={t("dataEntry.firstName.label")}
							required
						/>
						<Input
							placeholder={t("dataEntry.lastName.placeholder")}
							name="lastName"
							label={t("dataEntry.lastName.label")}
							required
						/>
						<Input
							placeholder={t("dataEntry.email.placeholder")}
							name="email"
							label={t("dataEntry.email.label")}
							type="email"
							required
						/>
						<Input
							placeholder={t("dataEntry.address.placeholder")}
							label={t("dataEntry.address.label")}
							name="street"
							required
						/>
						<Input
							placeholder={t("dataEntry.plz.placeholder")}
							name="plz"
							label={t("dataEntry.plz.label")}
							required
						/>
						<Input
							placeholder={t("dataEntry.location.placeholder")}
							name="city"
							label={t("dataEntry.location.label")}
							required
						/>
						<Input
							placeholder={t("dataEntry.phone.placeholder")}
							name="phone"
							type="tel"
							label={t("dataEntry.phone.label")}
							required
						/>
						<Button disabled={state.disabled} type="submit">
							{t("termin.dataEntry.submit")}
						</Button>
					</Form>
				</>
			)}
			{state.checkOut && department && service && (
				<>
					<h2>{t("termin.confirmation.title")}</h2>
					<div className="booking-information">
						<h3>{t("termin.confirmation.serviceInfo.title")}</h3>
						<div className="entry">
							<div>{t("termin.confirmation.serviceInfo.department")}</div>
							<div>{department.name}</div>
						</div>
						<div className="entry">
							<div>{t("termin.confirmation.serviceInfo.service")}</div>
							<div>{service.name}</div>
						</div>
						<div className="entry">
							<div>{t("termin.confirmation.serviceInfo.timeSlot")}</div>
							<div>
								{moment(dateFrom).format("dddd DD.MM.YYYY HH:mm")} - {moment(dateTo).format("HH:mm")}
							</div>
						</div>
					</div>
					<div className="booking-information">
						<h3>{t("dataEntry.main.personalDataTitle")}</h3>
						<div className="entry">
							<div>{t("dataEntry.main.name")}</div>
							<div>
								{personalData.firstName} {personalData.lastName}
							</div>
						</div>
						<div className="entry">
							<div>{t("dataEntry.main.address")}</div>
							<div>
								{personalData.address}
								<br />
								{personalData.plz}
								<br />
								{personalData.location}
							</div>
						</div>
						<div className="entry">
							<div>{t("dataEntry.main.email")}</div>
							<div>{personalData.email}</div>
						</div>
						<div className="entry">
							<div>{t("dataEntry.main.phone")}</div>
							<div>{personalData.phone}</div>
						</div>
					</div>

					{service.additional && (
						<div className="booking-information">
							<h3>{t("termin.confirmation.additionalInfo")}</h3>
							<ReactMarkdown>{service.additional}</ReactMarkdown>
						</div>
					)}

					<div className="booking-information important">
						<h3>{t("termin.confirmation.importantInfo.title")}</h3>
						<p dangerouslySetInnerHTML={{ __html: t("termin.confirmation.importantInfo.text") }}></p>
					</div>

					<Form validateOnSubmit onSubmit={book}>
						<Boolean name="privacy-read" required className="onedash-switch-container">
							<PrivacyText />
						</Boolean>
						<Button type="submit">{t("termin.confirmation.sendButton")}</Button>
					</Form>
				</>
			)}

			<Dialog closeable={false} isOpen={false} ref={serverResponseDialog} className="no-padding">
				<div className={state.serverResponse.type === "S" ? "success" : "hidden"}>
					<svg
						id="successAnimation"
						className={state.serverResponse.type === "S" ? "animated" : ""}
						xmlns="http://www.w3.org/2000/svg"
						width="110"
						height="110"
						viewBox="0 0 70 70">
						<path
							id="successAnimationResult"
							fill="#D8D8D8"
							d="M35,60 C21.1928813,60 10,48.8071187 10,35 C10,21.1928813 21.1928813,10 35,10 C48.8071187,10 60,21.1928813 60,35 C60,48.8071187 48.8071187,60 35,60 Z M23.6332378,33.2260427 L22.3667622,34.7739573 L34.1433655,44.40936 L47.776114,27.6305926 L46.223886,26.3694074 L33.8566345,41.59064 L23.6332378,33.2260427 Z"
						/>
						<circle
							id="successAnimationCircle"
							cx="35"
							cy="35"
							r="24"
							stroke="#979797"
							strokeWidth="2"
							strokeLinecap="round"
							fill="transparent"
						/>
						<polyline
							id="successAnimationCheck"
							stroke="#979797"
							strokeWidth="2"
							points="23 34 34 43 47 27"
							fill="transparent"
						/>
					</svg>
				</div>
				<div className={state.serverResponse.type === "E" ? "error" : "hidden"}>
					<svg
						version="1.1"
						id="errorAnimation"
						className={state.serverResponse.type === "E" ? "animated" : ""}
						xmlns="http://www.w3.org/2000/svg"
						width="110"
						height="110"
						viewBox="0 0 70 70">
						<path
							id="errorAnimationResult"
							fill="#D8D8D8"
							d="M35,9.3C21.1,9.3,9.9,20.5,9.9,34.4c0,13.9,11.2,25.1,25.1,25.1c13.9,0,25.1-11.2,25.1-25.1
								C60.1,20.5,48.8,9.3,35,9.3z M44.2,42.2l-1.3,1.3L35,35.7l-7.8,7.8l-1.3-1.3l7.8-7.8l-7.8-7.8l1.3-1.3L35,33l7.8-7.8l1.3,1.3
								l-7.8,7.8L44.2,42.2z"
						/>
						<circle
							id="errorAnimationCircle"
							stroke="#979797"
							strokeWidth="2"
							strokeLinecap="round"
							fill="transparent"
							cx="35"
							cy="34.4"
							r="24"
						/>
						<polyline
							id="errorAnimationX"
							stroke="#979797"
							strokeWidth="2"
							fill="transparent"
							d="M36.3,34.4l7.8,7.8l-1.3,1.3L35,35.7l-7.8,7.8l-1.3-1.3l7.8-7.8l-7.8-7.8l1.3-1.3L35,33l7.8-7.8l1.3,1.3
								L36.3,34.4z"
						/>
					</svg>
				</div>
				<div className={!state.serverResponse.type ? "hidden" : "response-message-title"}>
					{state.serverResponse.type === "E" && <h2>{t("termin.confirmation.response.error")}</h2>}
					{state.serverResponse.type === "S" && <h2>{t("termin.confirmation.response.success")}</h2>}
				</div>
				<div className={!state.serverResponse.type ? "hidden" : "response-message-text"}>
					{state.serverResponse.message}
				</div>
				<div className={!state.serverResponse.type ? "hidden" : "btn-wrapper"}>
					<button className="redirect-to-home" onClick={redirectToHome}>
						{t("app.backToHome")}
					</button>
				</div>
				{!state.serverResponse.type && <Spinner defaultVisible />}
			</Dialog>
		</div>
	);
};

export default DepartmentDataEntry;
