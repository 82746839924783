import React, { Component } from "react";
import "./PublicFacilitiesSelection.scss";
import Utils from "../../Helper/Utils";
import { BackendInterface } from "../../Helper/BackendInterface";
import SwipeableViews from "react-swipeable-views";
import Icon from "../../Resources/Icons/Icon";
import RouterHistory from "../../Helper/RouterHistory";
import PublicFacilityDatePicker from "./PublicFacilityDatePicker";
import { t } from "../../Helper/TranslationHandler";
import { Spinner, Dialog, Button } from "@onedash/tools";

export interface PublicFacilitiesListProps {}

const iconColor = "white";
const iconSize = 25;
class PublicFacilitiesList extends Component<PublicFacilitiesListProps> {
	datePrompt = React.createRef<Dialog>();
	spinner = React.createRef<Spinner>();
	swipeableActions: any;
	datePickerDialog = React.createRef<PublicFacilityDatePicker>();
	currentFacility: undefined;

	state = {
		showCalendar: false,
		searchEntries: {} as { schutzhuetten: any[]; buergerhaueser: any[] },
		count: 0,
		currentPageIndex: 0,
		startDate: undefined,
		endDate: undefined,
		unformattedDate: undefined,
		disabledDays: [],
	};
	componentDidMount() {
		Utils.setBackPath("/gebaeude-reservieren/type-selection");
		let dateRange = (this.props as any).match.params.date;
		let data = {};
		if (dateRange) {
			let startDate = new Date(Number(dateRange.split("-")[0]));
			let endDate = startDate;
			if (startDate) {
				this.setState({ unformattedDate: dateRange, startDate: Utils.formatDate(startDate) });
			}

			if (dateRange.split("-")[1]) {
				endDate = new Date(Number(dateRange.split("-")[1]));
				this.setState({ unformattedDate: dateRange, endDate: Utils.formatDate(endDate) });
			}
			data = { startDate: startDate.getTime(), endDate: endDate.getTime() };
		}
		BackendInterface.postData("/vg/publicFacilities/search", data, (response: any) => {
			if (response.type !== "E") {
				setTimeout(() => {
					if (this.spinner.current) this.spinner.current.hide();
					setTimeout(() => {
						this.setState({ searchEntries: response });
					}, 200);
				}, 500);
			}
		});
	}

	changePageIndex = (newIndex: any) => {
		this.setState({ currentPageIndex: newIndex });
	};

	toggleDetailView = (e: any) => {
		if (e.target.nodeName === "BUTTON" || e.target.nodeName === "A") {
			return;
		}
		e.currentTarget.classList.toggle("active");
		this.UpdateSwipeableViewHeight();
	};
	showDateSelectionScreen = () => {
		if (this.datePrompt.current) {
			this.datePrompt.current.hide();
			const facility: any = this.currentFacility;
			BackendInterface.postData(
				"/vg/publicFacilities/times",
				{ facilityType: facility.type, facilityId: facility.id },
				(result: any) => {
					this.setState({ disabledDays: result.bookings }, () => {
						if (this.datePickerDialog.current) this.datePickerDialog.current.open();
					});
				}
			);
		}
	};

	book = (facility: any) => {
		this.currentFacility = facility;
		if (this.state.startDate && this.datePrompt.current) {
			this.datePrompt.current.show();
		} else {
			BackendInterface.postData(
				"/vg/publicFacilities/times",
				{ facilityType: facility.type, facilityId: facility.id },
				(result: any) => {
					this.setState({ disabledDays: result.bookings }, () => {
						if (this.datePickerDialog.current) this.datePickerDialog.current.open();
					});
				}
			);
		}
	};
	redirectToConfirmation = (date: any) => {
		this.setState({ unformattedDate: date }, () => {
			this.redirectToSubmitForm();
		});
	};
	redirectToSubmitForm = () => {
		const facility: any = this.currentFacility;
		if (facility) {
			RouterHistory.push(
				`/gebaeude-reservieren/data-entry/${this.state.unformattedDate}/${facility.type}/${facility.id}`
			);
		}
	};

	getDateRange = () => {
		if (this.state.endDate) {
			return this.state.startDate + " - " + this.state.endDate;
		} else {
			return this.state.startDate;
		}
	};
	UpdateSwipeableViewHeight = () => {
		if (this.swipeableActions) this.swipeableActions.updateHeight();
	};

	render() {
		return (
			<div className="public-facilities-list">
				<div className="tab-wrapper">
					<button
						className={this.state.currentPageIndex === 0 ? "tab-name active" : "tab-name"}
						onClick={() => {
							this.setState({ currentPageIndex: 0 });
						}}>
						Schutzhütten
					</button>
					<button
						className={this.state.currentPageIndex === 1 ? "tab-name active" : "tab-name"}
						onClick={() => {
							this.setState({ currentPageIndex: 1 });
						}}>
						Bürgerhäuser
					</button>
				</div>
				{Object.keys(this.state.searchEntries).length > 0 && (
					<SwipeableViews
						action={((actions: any) => (this.swipeableActions = actions)) as any}
						animateHeight={true}
						onChangeIndex={this.changePageIndex}
						index={this.state.currentPageIndex}>
						<div className="public-facilities-type right-padding">
							<div className="list-wrapper">
								{this.state.startDate && <h3>{this.getDateRange()}</h3>}
								{this.state.searchEntries.schutzhuetten.map((entry, index) => (
									<div key={index} className="facility" onClick={this.toggleDetailView}>
										<span className="facility-location">{entry.place}</span>
										<span className="facility-name">{entry.name}</span>
										<h3>{t("publicFacilities.facilitiesList.placeHeading")}</h3>
										<div className="facility-place entry">
											<div>{t("publicFacilities.facilitiesList.additionalInfo.place")}</div>
											<div>{entry.street}</div>
										</div>
										<div className="additional-information entry">
											<div>{t("publicFacilities.facilitiesList.additionalInfo.location")}</div>
											<div>{entry.place}</div>
										</div>

										<h3>{t("publicFacilities.facilitiesList.facilityInfo")}</h3>
										<div className="entry additional-information">
											<div>{t("publicFacilities.facilitiesList.additionalInfo.numPersonIn")}</div>
											<div>{entry.numPersonsIn}</div>
										</div>
										<div className="entry additional-information">
											<div>{t("publicFacilities.facilitiesList.additionalInfo.numPKW")}</div>
											<div>{entry.numPKW}</div>
										</div>
										<h3>{t("publicFacilities.facilitiesList.equipment")}</h3>
										<div className="facility-icons">
											{entry.hasOutdoorTisch === 1 && (
												<div className="facility-icon">
													<Icon name="beer-bench" size={iconSize} color={iconColor}></Icon>
													<div className="icon-description">
														{t("publicFacilities.facilitiesList.icons.outdoorTable")}
													</div>
												</div>
											)}
											{entry.hasFireplace === 1 && (
												<div className="facility-icon">
													<Icon name="fire" size={iconSize} color={iconColor}></Icon>
													<div className="icon-description">
														{t("publicFacilities.facilitiesList.icons.fireplace")}
													</div>
												</div>
											)}
											{entry.hasElectricity === 1 && (
												<div className="facility-icon">
													<Icon name="flash" size={iconSize} color={iconColor}></Icon>
													<div className="icon-description">
														{t("publicFacilities.facilitiesList.icons.electricity")}
													</div>
												</div>
											)}
											{entry.hasEngine === 1 && (
												<div className="facility-icon">
													<Icon name="generator" size={iconSize} color={iconColor}></Icon>
													<div className="icon-description">
														{t("publicFacilities.facilitiesList.icons.engine")}
													</div>
												</div>
											)}
											{entry.hasToilets === 1 && (
												<div className="facility-icon">
													<Icon name="toilet" size={iconSize} color={iconColor}></Icon>
													<div className="icon-description">
														{t("publicFacilities.facilitiesList.icons.toilets")}
													</div>
												</div>
											)}
											{entry.hasKitchen === 1 && (
												<div className="facility-icon">
													<Icon name="chef" size={iconSize} color={iconColor}></Icon>
													<div className="icon-description">
														{t("publicFacilities.facilitiesList.icons.kitchen")}
													</div>
												</div>
											)}
											{entry.hasWashing === 1 && (
												<div className="facility-icon">
													<Icon name="sink" size={iconSize} color={iconColor}></Icon>
													<div className="icon-description">
														{t("publicFacilities.facilitiesList.icons.sink")}
													</div>
												</div>
											)}
											{entry.hasMobileNetwork === 1 && (
												<div className="facility-icon">
													<Icon name="phone" size={iconSize} color={iconColor}></Icon>
													<div className="icon-description">
														{t("publicFacilities.facilitiesList.icons.mobileNetwork")}
													</div>
												</div>
											)}
											{entry.isAccessible === 1 && (
												<div className="facility-icon">
													<Icon name="disabled" size={iconSize} color={iconColor}></Icon>
													<div className="icon-description">
														{t("publicFacilities.facilitiesList.icons.accessible")}
													</div>
												</div>
											)}
										</div>
										<h3>{t("publicFacilities.facilitiesList.headings.contact")}</h3>
										<div className="entry additional-information">
											<div>{t("publicFacilities.facilitiesList.additionalInfo.person")}</div>
											<div>{entry.person}</div>
										</div>
										{entry.email && (
											<div className="entry additional-information">
												<div>{t("publicFacilities.facilitiesList.additionalInfo.email")}</div>
												<div>
													<a href={`mailto:${entry.email}`}>{entry.email}</a>
												</div>
											</div>
										)}
										<div className="entry additional-information">
											<div>{t("publicFacilities.facilitiesList.additionalInfo.phone")}</div>
											<div>
												<a href={`tel:${entry.phone}`}>{entry.phone}</a>
											</div>
										</div>
										{entry.linkSatzung && (
											<>
												<h3>{t("publicFacilities.facilitiesList.misc")}</h3>
												<div className="entry additional-information">
													<div>
														{t(
															"publicFacilities.facilitiesList.additionalInfo.linkSatzung"
														)}
													</div>
													<div>
														<a href={entry.linkSatzung}>Satzung</a>
													</div>
												</div>
											</>
										)}
										{entry.email && (
											<div className="btn-wrapper">
												<button
													onClick={() => this.book({ type: "Schutzhuette", ...entry })}
													className="book-btn">
													{t("publicFacilities.facilitiesList.reserveBtn")}
												</button>
											</div>
										)}
									</div>
								))}
							</div>
						</div>
						<div className="public-facilities-type left-padding">
							<div className="list-wrapper">
								{this.state.startDate && <h3>{this.getDateRange()}</h3>}
								{this.state.searchEntries.buergerhaueser.map((entry, index) => (
									<div key={index} className="facility" onClick={this.toggleDetailView}>
										<span className="facility-location">{entry.place}</span>
										<span className="facility-name">{entry.name}</span>
										<h3>{t("publicFacilities.facilitiesList.placeHeading")}</h3>
										<div className="facility-place">
											<div>{t("publicFacilities.facilitiesList.additionalInfo.place")}</div>
											<div>{entry.street}</div>
										</div>

										<h3>{t("publicFacilities.facilitiesList.facilityInfo")}</h3>
										<div className="entry additional-information">
											<div>
												{t("publicFacilities.facilitiesList.additionalInfo.persNumStuhl")}
											</div>
											<div>{entry.persNumStuhl}</div>
										</div>
										<div className="entry additional-information">
											<div>
												{t("publicFacilities.facilitiesList.additionalInfo.persNumTisch")}
											</div>
											<div>{entry.persNumTisch}</div>
										</div>
										<div className="entry additional-information">
											<div>{t("publicFacilities.facilitiesList.additionalInfo.numPKW")}</div>
											<div>{entry.numPKW}</div>
										</div>
										<h3>{t("publicFacilities.facilitiesList.equipment")}</h3>
										<div className="facility-icons">
											{entry.stageAvailable === 1 && (
												<div className="facility-icon">
													<Icon name="stage" size={iconSize} color={iconColor}></Icon>
													<div className="icon-description">
														{t("publicFacilities.facilitiesList.icons.stage")}
													</div>
												</div>
											)}
											{entry.cookingAvailable === 1 && (
												<div className="facility-icon">
													<Icon name="chef" size={iconSize} color={iconColor}></Icon>
													<div className="icon-description">
														{t("publicFacilities.facilitiesList.icons.kitchen")}
													</div>
												</div>
											)}
											{entry.sinkAvailable === 1 && (
												<div className="facility-icon">
													<Icon name="sink" size={iconSize} color={iconColor}></Icon>
													<div className="icon-description">
														{t("publicFacilities.facilitiesList.icons.sink")}
													</div>
												</div>
											)}
											{entry.elevatorAvailable === 1 && (
												<div className="facility-icon">
													<Icon name="elevator" size={iconSize} color={iconColor}></Icon>
													<div className="icon-description">
														{t("publicFacilities.facilitiesList.icons.elevator")}
													</div>
												</div>
											)}
											{entry.disability_access === 1 && (
												<div className="facility-icon">
													<Icon name="disabled" size={iconSize} color={iconColor}></Icon>
													<div className="icon-description">
														{t("publicFacilities.facilitiesList.icons.accessible")}
													</div>
												</div>
											)}
											<h3>{t("publicFacilities.facilitiesList.headings.contact")}</h3>
											<div className="entry additional-information">
												<div>{t("publicFacilities.facilitiesList.additionalInfo.person")}</div>
												<div>{entry.person}</div>
											</div>
											{entry.email && (
												<div className="entry additional-information">
													<div>
														{t("publicFacilities.facilitiesList.additionalInfo.email")}
													</div>
													<div>
														<a href={`mailto:${entry.email}`}>{entry.email}</a>
													</div>
												</div>
											)}
											<div className="entry additional-information">
												<div>{t("publicFacilities.facilitiesList.additionalInfo.phone")}</div>
												<div>
													<a href={`tel:${entry.phone}`}>{entry.phone}</a>
												</div>
											</div>
											{entry.linkSatzung && (
												<>
													<h3>{t("publicFacilities.facilitiesList.misc")}</h3>
													<div className="entry additional-information">
														<div>
															{t(
																"publicFacilities.facilitiesList.additionalInfo.linkSatzung"
															)}
														</div>
														<div>
															<a href={entry.linkSatzung}>Satzung</a>
														</div>
													</div>
												</>
											)}
											{entry.email && (
												<div className="btn-wrapper">
													<button
														onClick={() => this.book({ type: "Buergerhaus", ...entry })}
														className="book-btn">
														{t("publicFacilities.facilitiesList.reserveBtn")}
													</button>
												</div>
											)}
										</div>
									</div>
								))}
							</div>
						</div>
					</SwipeableViews>
				)}

				<Spinner defaultVisible ref={this.spinner} />

				<PublicFacilityDatePicker
					disabledDays={this.state.disabledDays}
					ref={this.datePickerDialog}
					onDateRangeSelected={this.redirectToConfirmation}
				/>

				<Dialog className="date-prompt" buttons={[]} ref={this.datePrompt} isOpen={false}>
					<p>{t("publicFacilities.facilitiesList.datePrompt.selectedDate")}</p>
					<p className="date">{this.getDateRange()}</p>
					<p>{t("publicFacilities.facilitiesList.datePrompt.dateQuestion")}</p>

					<Button onClick={this.showDateSelectionScreen}>
						{t("publicFacilities.facilitiesList.datePrompt.changeDate")}
					</Button>
					<Button className="confirm" onClick={this.redirectToSubmitForm}>
						{t("publicFacilities.facilitiesList.datePrompt.continue")}
					</Button>
				</Dialog>
			</div>
		);
	}
}

export default PublicFacilitiesList;
