import Utils from "./Helper/Utils";

const isLocalhost = Boolean(
	window.location.hostname === "localhost" ||
		// [::1] is the IPv6 localhost address.
		window.location.hostname === "[::1]" ||
		// 127.0.0.1/8 is considered localhost for IPv4.
		window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

type Config = {
	onSuccess?: (registration: ServiceWorkerRegistration) => void;
	onUpdate?: (registration: ServiceWorkerRegistration) => void;
};

export function register(config?: Config) {
	if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
		// The URL constructor is available in all browsers that support SW.
		const publicUrl = new URL((process as { env: { [key: string]: string } }).env.PUBLIC_URL, window.location.href);
		if (publicUrl.origin !== window.location.origin) {
			// Our service worker won't work if PUBLIC_URL is on a different origin
			// from what our page is served on. This might happen if a CDN is used to
			// serve assets; see https://github.com/facebook/create-react-app/issues/2374
			return;
		}

		// PULL OUT THIS FUNCTION SO IT CAN BE REUSED
		const doRegister = () => {
			const swUrl = `${process.env.PUBLIC_URL}/custom-sw.js`;

			if (isLocalhost) {
				// This is running on localhost. Let's check if a service worker still exists or not.
				checkValidServiceWorker(swUrl, config);
			} else {
				// Is not localhost. Just register service worker
				registerValidSW(swUrl, config);
			}
		};

		// EITHER CALL REGISTER IMMEDIATELY, OR WAIT FOR WINDOW LOAD (CURRENT BEHAVIOR)
		if (config && (config as any).immediate) {
			doRegister();
		} else {
			window.addEventListener("load", doRegister);
		}

		Utils.isInstallable();
	}
}

function registerValidSW(swUrl: string, config?: Config) {
	if (config && (config as any).immediate) {
		navigator.serviceWorker.getRegistration(swUrl).then((registration) => {
			registration && registration.update();
		});
	} else {
		navigator.serviceWorker
			.register(swUrl)
			.then((registration) => {
				registration.onupdatefound = () => {
					const installingWorker = registration.installing;
					if (installingWorker == null) {
						return;
					}
					installingWorker.onstatechange = () => {
						if (installingWorker.state === "installed") {
							if (navigator.serviceWorker.controller) {
								Utils.updateAvailable();
								// Execute callback
								if (config && config.onUpdate) {
									config.onUpdate(registration);
								}
							} else {
								// Execute callback
								if (config && config.onSuccess) {
									config.onSuccess(registration);
								}
							}
						}
					};
				};
			})
			.catch((error) => {
				console.error("Error during service worker registration:", error);
			});
	}
}

function checkValidServiceWorker(swUrl: string, config?: Config) {
	// Check if the service worker can be found. If it can't reload the page.
	fetch(swUrl)
		.then((response) => {
			// Ensure service worker exists, and that we really are getting a JS file.
			const contentType = response.headers.get("content-type");
			if (response.status === 404 || (contentType != null && contentType.indexOf("javascript") === -1)) {
				// No service worker found. Probably a different app. Reload the page.
				navigator.serviceWorker.ready.then((registration) => {
					registration.unregister().then(() => {
						window.location.reload();
					});
				});
			} else {
				// Service worker found. Proceed as normal.
				registerValidSW(swUrl, config);
			}
		})
		.catch(() => {
			Utils.appIsOffline();
		});
}

export function unregister() {
	if ("serviceWorker" in navigator) {
		navigator.serviceWorker.ready.then((registration) => {
			registration.unregister();
		});
	}
}
