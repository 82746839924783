import React from "react";
import "./TimeSlotSelection.scss";
import moment, { Moment } from "moment";
import SwipeableViews from "react-swipeable-views";
import Icon from "../../Resources/Icons/Icon";
import Utils from "../../Helper/Utils";
import RouterHistory from "../../Helper/RouterHistory";
import { t } from "../../Helper/TranslationHandler";
import { Spinner } from "@onedash/tools";
import { useParams } from "react-router";
import { TemporaryTimeslot, useDepartmentServiceTimeslotsQuery } from "../../Resources/Generated/gql-types";
import ENV from "../../Helper/ENV";
let deLang = require("moment/locale/de.js");

moment.updateLocale("de", deLang);

export interface Day {
	date: Date;
	dayNum: number;
	timeSlots: Date[];
	approvedTimeSlots: Moment[];
	fullDayFree: boolean;
}

const TimeSlotSelection = () => {
	const { categoryId } = useParams<{ categoryId: string }>();
	const spinner = React.useRef<Spinner>(null);
	const [state, update] = React.useState({
		currentPageIndex: 0,
		serverError: undefined as undefined | string,
	});

	const { data: timeslotData } = useDepartmentServiceTimeslotsQuery({
		context: {
			headers: {
				"app-id": ENV.default.appId,
			},
		},
		pollInterval: 15000,
		fetchPolicy: "cache-and-network",
		variables: {
			serviceID: categoryId,
		},
		onCompleted: (r) => {
			if (spinner.current) spinner.current.hide();
		},
		onError: (err) => {
			update((s) => ({ ...s, serverError: err.message }));
		},
	});

	React.useEffect(() => {
		Utils.setBackPath("/terminvereinbarung/department-selection");
		moment.locale("de");
	}, []);

	const getDescription = (day: TemporaryTimeslot[]) => {
		if (day.length === 0) {
			return <>{t("termin.timeSlot.noSlotsAvailable")}</>;
		}
		return <>{t("termin.timeSlot.slotsAvailable")}</>;
	};

	const changePageIndex = (newIndex: any) => {
		update((s) => ({ ...s, currentPageIndex: newIndex }));
	};

	const book = (timeSlot: TemporaryTimeslot) => {
		RouterHistory.push(`/terminvereinbarung/data-entry/${categoryId}/${timeSlot.from}*${timeSlot.to}`);
	};

	const toggleDetailView = (e: any) => {
		if (e.target.nodeName === "BUTTON" || e.target.nodeName === "A") {
			return;
		}
		e.currentTarget.classList.toggle("active");
	};

	const timeslotMap = new Map<number, Map<number, TemporaryTimeslot[]>>();
	timeslotData?.departmentServiceTimeslots.forEach((timeslot) => {
		const weekNum = moment(timeslot.from).get("week");
		const dayNum = moment(timeslot.from).get("day");
		let t = timeslotMap.get(weekNum);
		if (!t) {
			t = new Map();
			timeslotMap.set(weekNum, t);
		}
		const slots = t.get(dayNum);
		if (!slots) {
			t.set(dayNum, [timeslot]);
		} else {
			slots.push(timeslot);
		}
	});
	const weekNums = timeslotMap.size;

	return (
		<div className="time-slot-selection">
			{state.serverError && (
				<h3 className="server-error" dangerouslySetInnerHTML={{ __html: state.serverError as any }}></h3>
			)}

			{timeslotMap && (
				<>
					{timeslotMap.size === 0 && <h2>{t("termin.timeSlot.noSlotsAvailable")}</h2>}
					{timeslotMap.size > 0 && <h2>{t("termin.timeSlot.selectDate")}</h2>}

					<SwipeableViews onChangeIndex={changePageIndex} index={state.currentPageIndex}>
						{Array.from(timeslotMap).map(([weekNum, week]) => (
							<div className="week" key={weekNum}>
								{Array.from(week).map(([dayIndex, day]) => (
									<div
										onClick={toggleDetailView}
										className={day.length === 0 ? "day disabled" : "day"}
										key={dayIndex}>
										<div className="day-name">{moment(day[0].from).format("dddd DD.MM.YYYY")}</div>
										<div className="day-description">{getDescription(day)}</div>

										<h3>{t("termin.timeSlot.availableTimeSlots")}</h3>
										<div className="day-time-slots">
											{day.map((timeSlot, index) => (
												<button
													key={index}
													className="time-slot"
													onClick={() => book(timeSlot)}>
													{moment(timeSlot.from).format("HH:mm")}
												</button>
											))}
										</div>
									</div>
								))}
							</div>
						))}
					</SwipeableViews>
					{timeslotMap.size > 0 && (
						<>
							<div
								className={state.currentPageIndex < weekNums - 1 ? "right-arrow" : "right-arrow hidden"}
								onClick={() => {
									if (state.currentPageIndex < weekNums - 1)
										update((s) => ({ ...s, currentPageIndex: s.currentPageIndex + 1 }));
								}}>
								<Icon name="back" color="black" size={25} />
							</div>

							<div
								className={state.currentPageIndex > 0 ? "left-arrow" : "left-arrow hidden"}
								onClick={() => {
									if (state.currentPageIndex > 0)
										update((s) => ({ ...s, currentPageIndex: s.currentPageIndex - 1 }));
								}}>
								<Icon name="back" color="black" size={25} />
							</div>

							<div className="week-indicator">
								{[...Array(weekNums)].map((_, i) => (
									<span
										key={i as any}
										onClick={() => update((s) => ({ ...s, currentPageIndex: i }))}
										className={state.currentPageIndex === i ? "active" : ""}
									/>
								))}
							</div>
						</>
					)}
				</>
			)}
			<Spinner defaultVisible ref={spinner} />
		</div>
	);
};

export default TimeSlotSelection;
