import React, { Component } from "react";
import { Dialog } from "@onedash/tools";
import Impress from "../About/Impress";
import Privacy from "../About/Privacy";
import VideoAGB from "../../Pages/Videos/VideoAGB";

interface PrivacyTextProps {
	onClick?: () => void;
	videoAGB?: boolean;
}

class PrivacyText extends Component<PrivacyTextProps> {
	impressDialog = React.createRef<Dialog>();
	privacyDialog = React.createRef<Dialog>();
	videoAGBDialog = React.createRef<Dialog>();

	openImpress = (e: any) => {
		e.preventDefault();
		if (this.impressDialog.current) this.impressDialog.current.show();
	};
	openPrivacy = (e: any) => {
		e.preventDefault();
		if (this.privacyDialog.current) this.privacyDialog.current.show();
	};
	openVideoAGB = (e: any) => {
		e.preventDefault();
		if (this.videoAGBDialog.current) this.videoAGBDialog.current.show();
	};
	onClick = (e: any) => {
		if (this.props.onClick && e.target.classList.contains("privacy-text")) this.props.onClick();
	};
	render() {
		return (
			<div className="privacy-text" onClick={this.onClick}>
				Hiermit bestätigen Sie, dass Sie unsere{" "}
				<a href="/privacy" onClick={this.openPrivacy}>
					Datenschutzerklärung
				</a>
				{this.props.videoAGB === true && (
					<>
						, unsere{" "}
						<a href="/video-agb" onClick={this.openVideoAGB}>
							Nutzungs&shy;bedingungen der Videoplattform
						</a>
					</>
				)}{" "}
				und unser{" "}
				<a href="/impress" onClick={this.openImpress}>
					Impressum
				</a>{" "}
				gelesen und verstanden haben.
				<Dialog title="Impressum" buttons={[]} ref={this.impressDialog}>
					<Impress inline />
				</Dialog>
				<Dialog title="Datenschutz&shy;erklärung" buttons={[]} ref={this.privacyDialog}>
					<Privacy inline />
				</Dialog>
				<Dialog title="Nutzungs&shy;bedingungen der Videoplattform" buttons={[]} ref={this.videoAGBDialog}>
					<VideoAGB inline />
				</Dialog>
			</div>
		);
	}
}

export default PrivacyText;
