import de_DE from "../Resources/Translations/de_DE/translations";

const languages = {
	de_DE,
};

const defaultLanguage = "de_DE";

export const t = (selector: string) => {
	let translation = languages[defaultLanguage] as any;
	let selectors = selector.split(".");
	selectors.forEach((sel) => {
		if (translation) {
			translation = translation[sel];
		} else {
			console.error("Translation " + selector + " is not defined");
			return selector;
		}
	});
	if (!translation) {
		console.error("Translation " + selector + " is not defined");
		return selector;
	}
	return translation;
};
