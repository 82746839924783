/* eslint react/style-prop-object: 0 */
import React, { Component } from "react";
import { Route } from "react-router-dom";
import "./Videos.scss";
import RouterHistory from "../../Helper/RouterHistory";
import { Form, Boolean, Popover, Input, Button, Spinner } from "@onedash/tools";
import PrivacyText from "../../Components/Utils/PrivacyText";
import { BackendInterface } from "../../Helper/BackendInterface";
import Utils from "../../Helper/Utils";
import MediathekMovie from "./Movie";
import { MediaRender } from "@onedash/tools";
import InfiniteScroll from "react-infinite-scroll-component";
import Icon from "../../Resources/Icons/Icon";
import TVIcon from "./Logo.jpg";

interface VideosProps {}

class Videos extends Component<VideosProps> {
	isRequesting = false;
	requestWaiting = false;
	currentPage = 0;
	state = {
		isLoading: true,
		isBlocked: false,
		step: 0,
		text: <></>,
		movies: [] as Movie[],
		tags: [] as string[],
		selectedTag: "Aktuelles" as string | undefined,
		searchString: undefined as string | undefined,
		movieCount: 0,
	};
	componentDidMount() {
		Utils.setBackPath("/");
		this.loadTags();
		this.loadMovies();
	}
	formRef = React.createRef<Form>();
	popoverRef = React.createRef<Popover>();
	toggleBoolean = () => {
		const form = this.formRef.current;
		if (form) {
			const obj = form.references.find((x) => x.name === "privacy-read" && x.ref !== null);
			obj?.ref.toggle();
		}
	};

	submitVideo = (data: any) => {
		this.setState({ isBlocked: true, step: 1 });
		BackendInterface.postData("/mediathek/movie", { url: data["video-url"] }, (response: any) => {
			setTimeout(() => {
				let text = (
					<>
						<h2 className="text-center">Vielen Dank für das Einreichen Ihres Videos.</h2>
						<h3 className="text-center">Wir werden dieses zeitnah prüfen und dann ggf. einstellen.</h3>
					</>
				);
				if (response.type === "E") {
					text = (
						<>
							<h2 className="text-center">
								Leider ist beim Einreichen des Videos ein Fehler aufgetreten.
							</h2>
							<h3 className="text-center">Bitte versuchen Sie es erneut.</h3>
						</>
					);
				}
				this.setState({ step: 2, text });
			}, 1500);
			setTimeout(() => {
				if (this.popoverRef.current) this.popoverRef.current.close(true);
			}, 4500);
		});
	};

	validateLink = (url: any): boolean => {
		if (!url) return false;
		const matches = [
			/^(?:http(s)?:\/\/)?[\w.-]+youtube(?:.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/gm,
			/^(?:http(s)?:\/\/)?[\w.-]+swr(.*)(?:.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/gm,
			/^(?:http(s)?:\/\/)?[\w.-]+ardmediathek(.*)(?:.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/gm,
			/^(?:http(s)?:\/\/)?[\w.-?]+youtu(?:.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/gm,
		];
		return matches.find((x) => url.match(x) !== null) !== undefined;
	};

	loadTags = async () => {
		const tagResponse: {
			message: { tags: string[] };
			type: string;
			status: number;
		} = await BackendInterface.get("/mediathek/tags");
		this.setState({ tags: tagResponse.message.tags });
	};

	loadMovies = async () => {
		if (this.isRequesting === false) {
			this.isRequesting = true;
			let queries = "";
			if (this.state.selectedTag !== undefined) {
				queries += `?tagSearch=${this.state.selectedTag}`;
			}
			if (this.state.searchString !== undefined) {
				queries += `${queries === "" ? "?" : "&"}titleSearch=${this.state.searchString}`;
			}
			queries += `${queries === "" ? "?" : "&"}page=${this.currentPage + 1}`;
			const movieResponse: MovieResponse = await BackendInterface.get("/mediathek/movie" + queries);
			const movies =
				this.currentPage === 0
					? movieResponse.message.movies
					: this.state.movies.concat(movieResponse.message.movies);

			this.setState({
				isLoading: false,
				movies,
				movieCount: movieResponse.message.entryCount,
			});
			setTimeout(() => {
				this.isRequesting = false;
				if (this.requestWaiting === true) {
					this.requestWaiting = false;
					this.loadMovies();
				}
			}, 200);
		} else {
			this.requestWaiting = true;
		}
	};

	toggleTag = (tag: string) => {
		this.currentPage = 0;
		let selectedTag: string | undefined = tag;
		Utils.smoothScrollTop();
		if (this.state.selectedTag === tag) {
			selectedTag = undefined;
		}
		this.setState({ selectedTag }, this.loadMovies);
	};

	movieSearch = (e: any) => {
		this.currentPage = 0;
		const searchString = e.value;
		this.setState({ searchString }, this.loadMovies);
	};

	loadMore = () => {
		this.currentPage++;
		this.loadMovies();
	};

	render() {
		return (
			<div className="mediathek-wrapper">
				<div className="mediathek">
					<img className="tv-icon" src={TVIcon} alt="TV Wittlich-Land Icon" />
					<div className="movie-toolbar">
						<Input
							placeholder="Suchen Sie nach einem Video ..."
							type="search"
							style="light-grey"
							name="movie-search"
							onChange={this.movieSearch}
						/>
						<Button mode="info" onClick={() => RouterHistory.push("/videos/add")}>
							<Icon size={25} name="upload" />
						</Button>
					</div>
					{!this.state.isLoading && (
						<div className="tags-wrapper">
							<div className="tags">
								<MediaRender type="mobile">
									<div className="placeholder" />
								</MediaRender>
								{this.state.tags.map((tag, index) => (
									<div
										key={index}
										onClick={() => this.toggleTag(tag)}
										className={this.state.selectedTag === tag ? "tag selected" : "tag"}>
										{tag}
									</div>
								))}

								<MediaRender type="mobile">
									<div className="placeholder" />
								</MediaRender>
							</div>
						</div>
					)}

					{!this.state.isLoading && this.state.movies.length === 0 && (
						<h2 className="not-found">
							Leider konnte kein Film mit {this.state.searchString !== "" && <>dem Namen </>}
							<span className="bold">{this.state.searchString}</span>{" "}
							{this.state.searchString !== "" && this.state.selectedTag && <> und </>}
							{this.state.selectedTag && (
								<>
									der Kategorie <span className="bold">{this.state.selectedTag}</span>
								</>
							)}{" "}
							gefunden werden
						</h2>
					)}
					{this.state.isLoading && <Spinner className="mediathek-spinner" defaultVisible />}
					{!this.state.isLoading && (
						<div className="movie-list">
							<InfiniteScroll
								dataLength={this.state.movies.length} //This is important field to render the next data
								next={this.loadMore}
								hasMore={this.state.movieCount > this.state.movies.length}
								loader={<Spinner defaultVisible />}
								scrollableTarget={
									window.innerWidth < 1200
										? document.querySelector("body")
										: document.querySelector(".content-wrapper")
								}
								endMessage={
									<h2 className="end-text">
										Leider gibts nicht mehr Videos.{" "}
										<div className="blue" onClick={() => RouterHistory.push("/videos/add")}>
											Reichen Sie Ihr Video ein.
										</div>
									</h2>
								}>
								{this.state.movies.map((movie, index) => (
									<MediathekMovie
										selectedTag={this.state.selectedTag}
										key={index}
										onTagPress={this.toggleTag}
										movie={movie}
									/>
								))}
							</InfiniteScroll>
						</div>
					)}
				</div>

				<Route
					path="/videos/add"
					render={() => (
						<Popover
							ref={this.popoverRef}
							closeable={!this.state.isBlocked}
							onClose={() => RouterHistory.push("/videos")}
							title={this.state.step === 0 ? "Neues Video einreichen" : ""}
							defaultVisible>
							<>
								{this.state.step === 0 && (
									<Form onSubmit={this.submitVideo} ref={this.formRef} validateOnSubmit>
										<Input
											onValidate={this.validateLink}
											minLength={5}
											required
											name="video-url"
											placeholder="Ihr Link zum YouTube Video"
										/>
										<Boolean name="privacy-read" required className="onedash-switch-container">
											<PrivacyText videoAGB onClick={this.toggleBoolean} />
										</Boolean>
										<Button type="submit">Video Einreichen</Button>
									</Form>
								)}
								{this.state.step === 1 && (
									<>
										<h2 className="wait-text">Bitte warten ...</h2>
										<Spinner defaultVisible />
									</>
								)}
								{this.state.step === 2 && this.state.text}
							</>
						</Popover>
					)}
				/>
			</div>
		);
	}
}

export default Videos;
