import React, { Component } from "react";
import ReactPlayer from "react-player";

interface MovieProps {
	movie: Movie;
	onTagPress: (tag: string) => void;
	selectedTag: string | undefined;
}

class MediathekMovie extends Component<MovieProps> {
	movie = React.createRef<HTMLDivElement>();
	state = {
		isPlaying: false,
		collapsed: true,
	};
	onClick = (e: any) => {
		if (this.props.movie.type === "YOUTUBE") {
			e.preventDefault();
		}
	};

	componentDidMount() {}
	render() {
		const m = this.props.movie;
		return (
			<div className="movie" ref={this.movie}>
				<a
					target="_blank"
					rel="noopener noreferrer"
					href={m.url}
					className="movie-thumbnail"
					onClick={this.onClick}>
					<>
						{m.type === "YOUTUBE" && (
							<ReactPlayer
								onReady={() => this.setState({ isPlaying: true })}
								pip
								playsinline
								className="youtube-player"
								light
								width="100%"
								height="auto"
								url={m.url}
								config={{
									youtube: {
										playerVars: { autoplay: 1, controls: 1, fs: 1, playsinline: 0, showinfo: 0 },
									},
								}}
							/>
						)}
						{m.type !== "YOUTUBE" && <img alt={m.title} src={m.thumbnailUrl} />}
						{!this.state.isPlaying && m.duration !== "00:00" && (
							<div className="duration">{m.duration}</div>
						)}
					</>
				</a>
				<h2>{m.title}</h2>
				<div
					onClick={() => this.setState({ collapsed: !this.state.collapsed })}
					className={this.state.collapsed === true ? "collapsed description" : "description"}>
					{m.description}
				</div>
				<div className="tags">
					{m.tags.map((tag, index) => (
						<div
							className={this.props.selectedTag === tag ? "tag selected" : "tag"}
							key={index}
							onClick={() => this.props.onTagPress(tag)}>
							{tag}
						</div>
					))}
				</div>
			</div>
		);
	}
}

export default MediathekMovie;
