import React from "react";
import Utils from "../../Helper/Utils";
import "./DepartmentSelection.scss";
import { Button, Spinner } from "@onedash/tools";
import { useParams } from "react-router";
import { useStornoServiceMutation } from "../../Resources/Generated/gql-types";
import ENV from "../../Helper/ENV";

const ServiceStorno = () => {
	const spinner = React.useRef<Spinner>(null);
	const { identifier } = useParams<{ identifier: string }>();

	const [cancelService, { error, loading, data }] = useStornoServiceMutation({
		variables: {
			identifier,
		},
		context: {
			headers: {
				"app-id": ENV.default.appId,
			},
		},
	});
	React.useEffect(() => {
		Utils.setBackPath("/");
	}, []);

	return (
		<div className="department-storno">
			{!loading ? (
				<>
					{!data && !error && (
						<>
							<h2 className="department-storno-title">Bitte bestätigen Sie die Stornierung</h2>
							<Button onClick={cancelService} cssStyles={{ width: "calc(100% - 80px)", margin: "40px" }}>
								Termin Stornieren
							</Button>
						</>
					)}
					{data && !error && <h2>Ihre Reservierung wurde erfolgreich storniert.</h2>}
					{error && (
						<>
							<h2>Ihre Reservierung konnte nicht storniert werden.</h2>
							<p style={{ textAlign: "center" }}>{error.message}</p>
						</>
					)}
				</>
			) : (
				<Spinner defaultVisible={loading} ref={spinner} />
			)}
		</div>
	);
};

export default ServiceStorno;
