import React, { Component } from "react";
import Utils from "../../Helper/Utils";
import "./PublicFacilitiesSelection.scss";
import { BackendInterface } from "../../Helper/BackendInterface";
import RouterHistory from "../../Helper/RouterHistory";
import { t } from "../../Helper/TranslationHandler";
import { Dialog, Form, Input, Spinner, Button, Boolean } from "@onedash/tools";
import PrivacyText from "../../Components/Utils/PrivacyText";

export interface PublicFacilitiesDataEntryProps {}

export interface PublicFacilitiesDataEntryState {}

class PublicFacilitiesDataEntry extends Component<PublicFacilitiesDataEntryProps, PublicFacilitiesDataEntryState> {
	serverResponseDialog = React.createRef<Dialog>();
	state = {
		disabled: false,
		checkOut: false,
		personalData: {} as any,
		facility: {} as any,
		serverResponse: {} as any,
	};
	startDate: any = undefined;
	endDate: any = undefined;
	facilityType: any = undefined;
	facilityId: any = undefined;
	componentDidMount() {
		const { date, facilityType, facilityId } = (this.props as any).match.params;
		this.startDate = date.split("-")[0];
		this.endDate = date.split("-")[1];
		this.facilityType = facilityType;
		this.facilityId = facilityId;
		Utils.setBackPath("/gebaeude-reservieren/facility-list/" + date);
		BackendInterface.postData("/vg/publicFacilities/times", { facilityType, facilityId }, (result: any) => {
			this.setState({ facility: result });
		});
	}

	getDateRange = () => {
		if (this.endDate) {
			return (
				Utils.formatDate(new Date(Number(this.startDate))) +
				" - " +
				Utils.formatDate(new Date(Number(this.endDate)))
			);
		} else {
			return Utils.formatDate(new Date(Number(this.startDate)));
		}
	};

	checkValidation = (values: any, form: Form) => {
		if (this.state.disabled) {
			if (form.validateInputs()) {
				this.setState({ disabled: false });
			}
		}
	};

	validateInput = (values: any, form: Form) => {
		if (form.validateInputs()) {
			this.setState({ checkOut: true, personalData: values });
		} else {
			this.setState({
				disabled: true,
			});
		}
	};

	book = () => {
		if (this.serverResponseDialog.current) {
			this.serverResponseDialog.current.show();
		}
		BackendInterface.postData(
			"/vg/publicFacilities/book",
			{
				facilityType: this.facilityType,
				facilityId: this.facilityId,
				timestamp_from: this.startDate,
				timestamp_to: this.endDate,
				...this.state.personalData,
			},
			(response: any) => {
				this.setState({
					serverResponse: response,
				});
			}
		);
	};

	redirectToHome = () => {
		RouterHistory.push("/");
	};
	render() {
		let personalData = this.state.personalData;
		return (
			<div className="public-facilities-data-entry">
				{!this.state.checkOut && (
					<>
						<h2>{t("dataEntry.title")}</h2>
						<Form onChange={this.checkValidation} onSubmit={this.validateInput}>
							<Input
								placeholder={t("dataEntry.firstName.placeholder")}
								name="firstName"
								label={t("dataEntry.firstName.label")}
								required
							/>
							<Input
								placeholder={t("dataEntry.lastName.placeholder")}
								name="lastName"
								label={t("dataEntry.lastName.label")}
								required
							/>
							<Input
								placeholder={t("dataEntry.email.placeholder")}
								name="email"
								label={t("dataEntry.email.label")}
								type="email"
								required
							/>
							<Input
								placeholder={t("dataEntry.address.placeholder")}
								label={t("dataEntry.address.label")}
								name="address"
								required
							/>
							<Input
								placeholder={t("dataEntry.plz.placeholder")}
								name="plz"
								label={t("dataEntry.plz.label")}
								required
							/>
							<Input
								placeholder={t("dataEntry.location.placeholder")}
								name="location"
								label={t("dataEntry.location.label")}
								required
							/>
							<Input
								placeholder={t("dataEntry.phone.placeholder")}
								name="phone"
								type="text"
								label={t("dataEntry.phone.label")}
								required
							/>

							<Input
								placeholder={t("dataEntry.additionalMessage.placeholder")}
								name="additional_message"
								type="textarea"
								label={t("dataEntry.additionalMessage.label")}
							/>
							<Button disabled={this.state.disabled} type="submit">
								{t("publicFacilities.dataEntry.confirmBtn")}
							</Button>
						</Form>
					</>
				)}
				{this.state.checkOut && (
					<>
						<h2>{t("publicFacilities.dataEntry.bookingHeading")}</h2>
						<div className="booking-information">
							<h3>{t("publicFacilities.dataEntry.reservationInfos")}</h3>
							<div className="entry">
								<div>{t("publicFacilities.dataEntry.reservationDate")}</div>
								<div>{this.getDateRange()}</div>
							</div>
							<div className="entry">
								<div>{t("publicFacilities.dataEntry.facilityName")}</div>
								<div>{this.state.facility.name}</div>
							</div>
							<div className="entry">
								<div>{t("publicFacilities.dataEntry.place")}</div>
								<div>
									{this.state.facility.street}
									<br />
									{this.state.facility.place}
								</div>
							</div>
						</div>
						<div className="booking-information">
							<h3>{t("publicFacilities.dataEntry.personalData")}</h3>
							<div className="entry">
								<div>{t("dataEntry.name.label")}</div>
								<div>
									{personalData.firstName} {personalData.lastName}
								</div>
							</div>
							<div className="entry">
								<div>{t("dataEntry.address.label")}</div>
								<div>
									{personalData.address}
									<br />
									{personalData.plz}
									<br />
									{personalData.location}
								</div>
							</div>
							<div className="entry">
								<div>{t("dataEntry.email.label")}</div>
								<div>{personalData.email}</div>
							</div>
						</div>
						{personalData.additional_message.length > 0 && (
							<div className="booking-information">
								<h3>{t("dataEntry.additionalMessage.label")}</h3>
								<p>{personalData.additional_message}</p>
							</div>
						)}

						<Form validateOnSubmit onSubmit={this.book}>
							<Boolean name="privacy-read" required className="onedash-switch-container">
								<PrivacyText />
							</Boolean>

							<Button className="booking-submit" disabled={this.state.disabled} type="submit">
								{t("publicFacilities.dataEntry.bookBtn")}
							</Button>
						</Form>
					</>
				)}

				<Dialog closeable={false} isOpen={false} ref={this.serverResponseDialog} className="no-padding">
					<div className={this.state.serverResponse.type === "S" ? "success" : "hidden"}>
						<svg
							id="successAnimation"
							className={this.state.serverResponse.type === "S" ? "animated" : ""}
							xmlns="http://www.w3.org/2000/svg"
							width="110"
							height="110"
							viewBox="0 0 70 70">
							<path
								id="successAnimationResult"
								fill="#D8D8D8"
								d="M35,60 C21.1928813,60 10,48.8071187 10,35 C10,21.1928813 21.1928813,10 35,10 C48.8071187,10 60,21.1928813 60,35 C60,48.8071187 48.8071187,60 35,60 Z M23.6332378,33.2260427 L22.3667622,34.7739573 L34.1433655,44.40936 L47.776114,27.6305926 L46.223886,26.3694074 L33.8566345,41.59064 L23.6332378,33.2260427 Z"
							/>
							<circle
								id="successAnimationCircle"
								cx="35"
								cy="35"
								r="24"
								stroke="#979797"
								strokeWidth="2"
								strokeLinecap="round"
								fill="transparent"
							/>
							<polyline
								id="successAnimationCheck"
								stroke="#979797"
								strokeWidth="2"
								points="23 34 34 43 47 27"
								fill="transparent"
							/>
						</svg>
					</div>
					<div className={this.state.serverResponse.type === "E" ? "error" : "hidden"}>
						<svg
							version="1.1"
							id="errorAnimation"
							className={this.state.serverResponse.type === "E" ? "animated" : ""}
							xmlns="http://www.w3.org/2000/svg"
							width="110"
							height="110"
							viewBox="0 0 70 70">
							<path
								id="errorAnimationResult"
								fill="#D8D8D8"
								d="M35,9.3C21.1,9.3,9.9,20.5,9.9,34.4c0,13.9,11.2,25.1,25.1,25.1c13.9,0,25.1-11.2,25.1-25.1
								C60.1,20.5,48.8,9.3,35,9.3z M44.2,42.2l-1.3,1.3L35,35.7l-7.8,7.8l-1.3-1.3l7.8-7.8l-7.8-7.8l1.3-1.3L35,33l7.8-7.8l1.3,1.3
								l-7.8,7.8L44.2,42.2z"
							/>
							<circle
								id="errorAnimationCircle"
								stroke="#979797"
								strokeWidth="2"
								strokeLinecap="round"
								fill="transparent"
								cx="35"
								cy="34.4"
								r="24"
							/>
							<polyline
								id="errorAnimationX"
								stroke="#979797"
								strokeWidth="2"
								fill="transparent"
								d="M36.3,34.4l7.8,7.8l-1.3,1.3L35,35.7l-7.8,7.8l-1.3-1.3l7.8-7.8l-7.8-7.8l1.3-1.3L35,33l7.8-7.8l1.3,1.3
								L36.3,34.4z"
							/>
						</svg>
					</div>
					<div className={!this.state.serverResponse.type ? "hidden" : "response-message-title"}>
						{this.state.serverResponse.type === "E" && (
							<h2>{t("publicFacilities.dataEntry.bookingFailure")}</h2>
						)}
						{this.state.serverResponse.type === "S" && (
							<h2>{t("publicFacilities.dataEntry.bookingSuccess")}</h2>
						)}
					</div>
					<div className={!this.state.serverResponse.type ? "hidden" : "response-message-text"}>
						{this.state.serverResponse.message}
					</div>
					<div className={!this.state.serverResponse.type ? "hidden" : "btn-wrapper"}>
						<button className="redirect-to-home" onClick={this.redirectToHome}>
							{t("app.backToHome")}
						</button>
					</div>
					{!this.state.serverResponse.type && <Spinner defaultVisible />}
				</Dialog>
			</div>
		);
	}
}

export default PublicFacilitiesDataEntry;
