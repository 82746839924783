import React, { Component } from "react";
import "./App.scss";
import "./onedash-tools.scss";
import Home from "../Home/Home";
import Header from "../../Components/Header/Header";
import Navigation from "../../Components/Navigation/Navigation";
import { Route, Switch } from "react-router-dom";
import News from "../../Components/News/News";
import { createStore } from "redux";
import MainReducer from "../../Helper/Reducer";
import About from "../../Components/About/About";
import DepartmentSelection from "../DepartmentSelection/DepartmentSelection";
import PublicFacilitiesSelection from "../PublicFacilitiesSelection/PublicFacilitiesSelection";
import PublicFacilitiesList from "../PublicFacilitiesSelection/PublicFacilitiesList";
import PublicFacilitiesDataEntry from "../PublicFacilitiesSelection/PublicFacilitiesDataEntry";
import TimeSlotSelection from "../../Components/TimeSlotSelection/TimeSlotSelection";
import DepartmentDataEntry from "../DepartmentSelection/DepartmentDataEntry";
import CookieConsent from "react-cookie-consent";
import { t } from "../../Helper/TranslationHandler";
import MediaRender from "./MediaRender";
import Utils from "../../Helper/Utils";
import RouterHistory from "../../Helper/RouterHistory";
import Videos from "../Videos/Videos";

import { Dialog } from "@onedash/tools";
import Impress from "../../Components/About/Impress";
import Privacy from "../../Components/About/Privacy";
import VideoAGB from "../Videos/VideoAGB";
import GiftCards from "../GiftCards/GiftCards";
import GiftCardSubmit from "../GiftCards/GiftCardSubmit";
import ServerStatus from "../../Helper/ServerStatus";
import ENV from "../../Helper/ENV";
import ServiceStorno from "../DepartmentSelection/ServiceStorno";

declare global {
	interface Window {
		reduxStore: any;
		deferredPrompt: any;
		_env: any;
	}
}

window.reduxStore = createStore(MainReducer);

class App extends Component<any> {
	updateDialog = React.createRef<Dialog>();

	state = {
		mode: "expanded" as headerModes,
		mainModeIsChanging: false,
		title: t("app.title"),
		updateAvailableTriggered: false,
		networkState: undefined as "online" | "offline" | undefined,
	};

	contentIsChanging = () => {
		this.setState({ mainModeIsChanging: true });
		setTimeout(() => {
			this.setState({ mainModeIsChanging: false });
		}, 500);
	};

	changeMode = (mode: headerModes, title: string) => {
		this.setState({ mode, title });
	};

	buildAppContentClasses = () => {
		let classList = "app-content";
		if (this.state.mainModeIsChanging === true) {
			classList += " content-change";
		}
		return classList;
	};

	constructor(props: any) {
		super(props);
		new ServerStatus(ENV.serverStatus.url, ENV.serverStatus.id);
	}

	checkUpdate = () => {
		let updateAvailable = window.reduxStore.getState().updateAvailable;
		if (updateAvailable === true && this.updateDialog.current && !this.state.updateAvailableTriggered) {
			this.setState({ updateAvailableTriggered: true });
			this.updateDialog.current.show();
		}
	};
	checkNetworkState = () => {
		let online = window.reduxStore.getState().online;
		if (online !== undefined) {
			this.setState({
				networkState: online === true ? "online" : "offline",
			});
		}
	};

	componentDidMount() {
		this.checkUpdate();
		window.reduxStore.subscribe(() => {
			this.checkUpdate();
			this.checkNetworkState();
		});

		window.addEventListener("online", Utils.appIsOnline);
		window.addEventListener("offline", Utils.appIsOffline);
		window.addEventListener("beforeinstallprompt", function (e) {
			e.preventDefault();
			window.deferredPrompt = e;
			return false;
		});
		window.addEventListener("deviceorientation", this.adjustBodySize, true);
		window.addEventListener("resize", this.adjustBodySize);
		this.adjustBodySize();
	}
	adjustBodySize = () => {
		if (window.innerWidth < 720) {
			document.body.style.height = `${window.innerHeight - 86}px`;
		} else {
			document.body.style.height = `${window.innerHeight}px`;
		}
	};
	reloadApp = () => {
		window.location.reload(true);
	};

	render() {
		return (
			<div className="app">
				<Dialog
					settings={{ maxWidth: 300 }}
					className="update-available"
					closeable={false}
					ref={this.updateDialog}
					isOpen={false}
					buttons={[
						{
							mode: "primary",
							type: "default",
							onClick: this.reloadApp,
							closeOnClick: false,
							text: t("base.update.button"),
						},
					]}
					title={t("base.update.title")}>
					<p>{t("base.update.text")}</p>
				</Dialog>

				{this.state.networkState === "offline" && (
					<div className="offline-indicator offline-online-indicator">
						<p>{t("base.app.offline")}</p>
					</div>
				)}
				{this.state.networkState === "online" && (
					<div className="online-indicator offline-online-indicator">
						<p>{t("base.app.online")}</p>
					</div>
				)}

				<CookieConsent
					location="top"
					buttonText={t("base.cookie.accept")}
					declineButtonText={t("base.cookie.decline")}
					enableDeclineButton={true}
					cookieName="settings-cookie"
					style={{ background: "#2B373B" }}
					buttonStyle={{ color: "#4e503b", fontSize: "1rem" }}
					declineButtonStyle={{ fontSize: "1rem" }}
					expires={365}>
					{t("base.cookie.text")}
				</CookieConsent>

				<div className="content-splitter">
					{!RouterHistory.location.pathname.includes("aktuelles") && (
						<MediaRender size={1200} type="desktop">
							<div className="desktop-news">
								<h1>Aktuelle Nachrichten</h1>
								<News />
							</div>
						</MediaRender>
					)}
					<div className="content-wrapper">
						<Header mode={this.state.mode} title={this.state.title} />

						<div className={this.buildAppContentClasses()}>
							<div className="app-bg" />
							<Switch>
								<Route
									exact
									path="/aktuelles"
									render={(props) => (
										<RouterComp {...props} component={News} title="Aktuelle Nachrichten" />
									)}
								/>
								<Route
									exact
									path="/about"
									render={(props) => (
										<RouterComp {...props} component={About} title="Über diese App" />
									)}
								/>
								<Route
									path="/videos"
									render={(props) => (
										<RouterComp {...props} component={Videos} title="TV Wittlich-Land" />
									)}
								/>

								<Route
									exact
									path="/terminvereinbarung/department-selection"
									render={(props) => (
										<RouterComp
											{...props}
											component={DepartmentSelection}
											title="Online Terminvereinbarung"
										/>
									)}
								/>
								<Route
									exact
									path="/terminvereinbarung/time-slot-selection/:categoryId"
									render={(props) => (
										<RouterComp
											{...props}
											component={TimeSlotSelection}
											title="Online Terminvereinbarung"
										/>
									)}
								/>
								<Route
									exact
									path="/terminvereinbarung/data-entry/:categoryId/:date"
									render={(props) => (
										<RouterComp
											{...props}
											component={DepartmentDataEntry}
											title="Online Terminvereinbarung"
										/>
									)}
								/>
								<Route
									exact
									path="/terminvereinbarung/storno/:identifier"
									render={(props) => (
										<RouterComp {...props} component={ServiceStorno} title="Termin Stornierung" />
									)}
								/>
								<Route
									path="/terminvereinbarung/"
									render={(props) => (
										<RouterComp
											{...props}
											component={DepartmentSelection}
											title="Online Terminvereinbarung"
										/>
									)}
								/>

								<Route
									path="/online-gutscheine/submit"
									exact
									render={(props) => (
										<RouterComp {...props} component={GiftCardSubmit} title="EIN.KAUF MIT HERZ" />
									)}
								/>
								<Route
									path="/online-gutscheine"
									render={(props) => (
										<RouterComp {...props} component={GiftCards} title="EIN.KAUF MIT HERZ" />
									)}
								/>
								<Route
									path="/online-fundbuero"
									component={() => {
										window.location.href =
											"https://antrag-kommunal.service.rlp.de/civ.public/start.html?oe=00.00.WITTVG&mode=cc&cc_key=OZG_Fundsachen";
										return null;
									}}
								/>
								<Route
									exact
									path="/gebaeude-reservieren/type-selection"
									render={(props) => (
										<RouterComp
											{...props}
											component={PublicFacilitiesSelection}
											title="Öffentliche Einrichtung Reservieren"
										/>
									)}
								/>
								<Route
									exact
									path="/gebaeude-reservieren/facility-list/:date"
									render={(props) => (
										<RouterComp
											{...props}
											component={PublicFacilitiesList}
											title="Öffentliche Einrichtung Reservieren"
										/>
									)}
								/>
								<Route
									exact
									path="/gebaeude-reservieren/data-entry/:date/:facilityType/:facilityId"
									render={(props) => (
										<RouterComp
											{...props}
											component={PublicFacilitiesDataEntry}
											title="Öffentliche Einrichtung Reservieren"
										/>
									)}
								/>
								<Route
									path="/gebaeude-reservieren/facility-list"
									render={(props) => (
										<RouterComp
											{...props}
											component={PublicFacilitiesList}
											title="Öffentliche Einrichtung Reservieren"
										/>
									)}
								/>

								<Route
									exact
									path="/gebaeude-reservieren/"
									render={(props) => (
										<RouterComp
											{...props}
											component={PublicFacilitiesSelection}
											title="Öffentliche Einrichtung Reservieren"
										/>
									)}
								/>
								<Route exact path="/impress/" component={Impress} />
								<Route exact path="/privacy/" component={Privacy} />
								<Route exact path="/video-agb/" component={VideoAGB} />
								<Route path="/" component={Home} />
							</Switch>
						</div>
					</div>
				</div>
				<Navigation mainContentIsChanging={this.contentIsChanging} changeDisplayMode={this.changeMode} />
			</div>
		);
	}
}

interface RouterCompProps {
	component: any;
	title: string;
}

class RouterComp extends Component<RouterCompProps> {
	componentDidMount() {
		document.title = this.props.title;
		(document.querySelector('meta[name="og:title"]') as any).setAttribute("content", this.props.title);
	}
	render() {
		const PageComponent = this.props.component;
		return <PageComponent {...this.props} />;
	}
}

export default App;
